import React, { useEffect, useState } from 'react';

import { TableCell }from "@mui/material";

function RelatorioPoucoAssistido({ relatorio }) {
    const [tituloAsset, setTituloAsset] = useState("");
    const [generoAsset, setGeneroAsset] = useState("");
    const [duracaoAsset, setDuracaoAsset] = useState(0);

    const handleTratamentoDados = () => {
        const { titulo, genero, duracao } = relatorio;

        setTituloAsset(titulo);
        setGeneroAsset(genero);
        setDuracaoAsset(duracao);
    }

    useEffect(() => {
        return handleTratamentoDados();
    }, [])

    return (
        <>
            <TableCell>{tituloAsset}</TableCell>
            <TableCell>{generoAsset}</TableCell>
            <TableCell>{duracaoAsset}</TableCell>
        </>
    )
}

export default RelatorioPoucoAssistido;
