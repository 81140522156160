import React, { useState } from "react";

import { Icon, IconButton, TableCell, TableRow } from "@mui/material";
import WarningModal from "components/WarningModal";
import { getAxios } from "utils";
import ChannelModal from "../ChannelModal";

function ChannelTile({ channel, updatedCallback }) {
    const [open, setOpen] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);

    const { id, name } = channel;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    }
    
    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/channels';
        const params = { id }

        try {
            await axiosInstance.delete(uri, { params });
            updatedCallback(true);
            handleCloseDeleteWarning();
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }
    
    const handleOpenDeleteWarning = (event) => {
        event.stopPropagation();
        setDeleteWarning(true);
    }
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Canal'
                message={`Tem certeza que deseja deletar o canal ${channel.name}?`}
            />
            <ChannelModal open={open} handleClose={handleClose} channel={channel} />
            <TableRow style={{ cursor: 'pointer' }} onClick={handleOpen}>
                <TableCell scope="row">{id}</TableCell>
                <TableCell scope="row">{name}</TableCell>
                <TableCell scope="row">
                    <IconButton onClick={handleOpenDeleteWarning}>
                        <Icon>delete</Icon>
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}

export default ChannelTile;
