import React, { useEffect, useState } from 'react';

import { TableCell }from "@mui/material";

function RelatorioAsset({ relatorio }) {
    const [tituloAsset, setTituloAsset] = useState("");
    const [episodiosAsset, setEpisodiosAsset] = useState(0);
    const [duracaoAsset, setDuracaoAsset] = useState(0);

    const handleTratamentoDados = () => {
        const { titulo, episodio, duracao } = relatorio;

        setTituloAsset(titulo);
        setEpisodiosAsset(episodio);
        setDuracaoAsset(duracao);
    }

    useEffect(() => {
        return handleTratamentoDados();
    }, [])

    return (
        <>
            <TableCell>{tituloAsset}</TableCell>
            <TableCell>{episodiosAsset}</TableCell>
            <TableCell>{duracaoAsset}</TableCell>
        </>
    )
}

export default RelatorioAsset;