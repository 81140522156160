import { Navigate } from "react-router-dom";
import { validateAccessToken } from "utils";

function PrivateRoute(props) {
    if(!validateAccessToken()) {
        return <Navigate to="/authentication/sign-in" />
    }

    return props.children;
}

export default PrivateRoute;