import React, { useEffect, useState } from "react";
import { getAxios } from "utils";

import { Box, Button, CircularProgress } from "@mui/material";
import ContentTile from "../ContentTile";
import { SortableTree } from "dnd-kit-sortable-tree";
import _ from "lodash";

function HierarchyTabPanel({ catalogId, value, index, onClose }) {
    const [hierarchy, setHierarchy] = useState([]);
    const [error, setError] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [editing, setEditing] = useState(null);
    const [openedCategories, setOpenedCategories] = useState([]);

    const handleGetHierarchy = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/hierarchy";
        let response;
        const params = { catalog: catalogId }

        try {
            response = await axiosInstance.get(uri, { params });

            let hierarchy = response.data.result.hierarchy;
            setHierarchy(hierarchy);
            setError(undefined);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });

                return;
            }

            setError("Erro interno");
        }

        setLoading(false);
    }

    const handleUpdateHierarchy = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/hierarchy";
        let response;

        const data = new FormData();
        data.append('hierarchy', JSON.stringify(hierarchy));
        data.append('catalog', catalogId);

        try {
            response = await axiosInstance.post(uri, data);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);
    }

    const findItemById = (id, tree) => {
        const foundItem = _.find(tree, { 'id': id });

        if (foundItem) return foundItem;

        for (const item of tree) {
            if (item.children && item.children.length > 0) {
                const foundInChildren = findItemById(id, item.children);
                if (foundInChildren) return foundInChildren;
            }
        }

        return null;
    };

    const getCategoryChildren = async (id) => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/listContent";
        let response;
        const params = { id }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        let contents = response.data.result.contents;
        handleUpdateChildren(`category_${id}`, contents);
        setLoading(false);
    }

    const handleGetOpenedCategories = () => {
        openedCategories.forEach(getCategoryChildren);
    }

    const handleUpdateChildren = (id, children) => {
        const parsedId = id.replace(/\D/g, "");

        if (
            !openedCategories.includes(parsedId)
            && children.length != 0
        ) {
            setOpenedCategories([...openedCategories, parsedId])
        } else if (children.length == 0) {
            setOpenedCategories(categories => {
                const myArray = categories;
                const index = myArray.indexOf(2);
                if (index) myArray.splice(index, 1);

                return myArray
            })
        }

        setHierarchy((prevHierarchy) => {
            const newHierarchy = JSON.parse(JSON.stringify(prevHierarchy));

            const content = findItemById(id, newHierarchy);

            if (content) content.children = children;

            return newHierarchy;
        });
    }

    useEffect(() => {
        if (value === index) handleGetHierarchy();
    }, [value, index]);

    const handleItemsChange = (value) => setHierarchy(value);

    const handleUpdate = async () => {
        await handleGetHierarchy();
        handleGetOpenedCategories();
    }

    const handleEditing = (value) => {
        handleUpdateHierarchy();
        setEditing(value);
    }
    const handleStopEditing = () => setEditing(null);

    const handleSubmit = () => {
        handleUpdateHierarchy();
        onClose();
    }

    return (
        <div hidden={value !== index}>
            <div style={{ maxHeight: 380, overflowY: 'auto' }}>
                {value === index && isLoading
                    ? <CircularProgress />
                    : <SortableTree
                        items={hierarchy}
                        disableSorting={!!editing}
                        TreeItemComponent={React.forwardRef((props, ref) =>
                            <ContentTile
                                catalogId={catalogId}
                                props={props}
                                innerRef={ref}
                                editing={editing}
                                onUpdate={handleUpdate}
                                onEditing={handleEditing}
                                onStopEditing={handleStopEditing}
                                onUpdateChildren={handleUpdateChildren}
                            />
                        )}
                        onItemsChanged={handleItemsChange}
                    />
                }
            </div>
            <Box gap={5}>
                <Button color="error" onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </Box>
        </div>
    )
}

export default HierarchyTabPanel;