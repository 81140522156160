import React, { useState } from "react";
import PropTypes from "prop-types";

import {
    Dialog, DialogContent, DialogTitle, IconButton, Icon, Button
} from "@mui/material";
import ScreenFormModal from "../ScreenFormModal";
import TitleValue from "components/TitleValue";
import { useNavigate } from "react-router-dom";

function ScreenModal({ open, handleClose, screen }) {
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false);
    const { id, name } = screen;

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        handleClose();
    }

    const handleEditScreen = () => navigate(`/screen/${id}`);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='sm'
        >
            <DialogTitle disableTypography display='flex' justifyContent='space-between'>
                {name}
                <IconButton onClick={handleEdit}>
                    <Icon>edit</Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ScreenFormModal
                    open={edit}
                    handleClose={handleStopEdit}
                    screen={screen}
                />
                <TitleValue
                    title="Nome"
                    value={screen.name}
                />
                <TitleValue
                    title="Branch"
                    value={screen.branchName}
                />
                <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={handleEditScreen}
                    startIcon={<Icon>edit</Icon>}
                >
                    Editar Tela
                </Button>
            </DialogContent>
        </Dialog>
    )
}

ScreenModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default ScreenModal;