import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as yup from 'yup';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Avatar
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import DefaultCheckField from "components/DefaultCheckField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { imageUrl } from "utils";

function AvatarFormModal({ open, handleClose, avatar = null }) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(avatar != null
        ? avatar.name
        : 'Novo Avatar'
    );

    const initialState = {
        active: true, name: '', image: '', imageUrl: ''
    };

    const formattedAvatar = () => ({
        ...avatar,
        imageUrl: imageUrl(
            window.stxConfig.imageTypes.avatar,
            avatar.id, 150, 150
        )
    });

    const saveChanges = async (values) => {
        const formData = new FormData();

        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (avatar != null) {
                const uri = '/crm/admin/avatars/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/crm/admin/avatars';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue
    } = useFormik({
        initialValues: avatar ? formattedAvatar() : initialState,
        onSubmit: saveChanges,
        validationSchema,
    });

    useEffect(() => {
        if (avatar != null) setTitle(values.name);
    }, [avatar, values.name])

    useEffect(() => {
        if (open) resetForm();
    }, [open])

    useEffect(() => console.log(values.image), [values.image])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DefaultTextField
                    label="Nome"
                    value={values.name}
                    name='name'
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    required
                />

                <Avatar style={{width: 100, height: 100}} src={values.imageUrl} />
                <input
                    accept="image/*"
                    onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        const url = URL.createObjectURL(file);
                        setFieldValue('imageUrl', url)
                        setFieldValue('image', file)
                    }
                    }
                    type="file"
                />

                <DefaultCheckField
                    label="Avatar ativo?"
                    required
                    name="active"
                    checked={values.active}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

AvatarFormModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default AvatarFormModal;
