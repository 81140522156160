import { IconButton, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAxios } from 'utils';
import WarningModal from 'components/WarningModal';
import { useNavigate } from 'react-router-dom';
import StreamFormModal from './StreamFormModal';

function StreamTile ({stream, channelId, updateCallBack}) {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updateCallBack(true);
    }

    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/channelStreams';
        const params = {
            userId: channelId,
            id: stream.id
        }

        try {
            await axiosInstance.delete(uri, { params });
            updateCallBack(true);
            handleCloseDeleteWarning();
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const handleOpenDeleteWarning = () => setDeleteWarning(true);
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Stream'
                message={`Tem certeza que deseja deletar a stream para ${stream.technology}?`}
            />

            <StreamFormModal 
                open={open} 
                handleClose={handleClose} 
                channelId={channelId}
                stream={stream}
            />
            <ListItem>
                <ListItemText 
                    primary={stream.technology} 
                    secondary={stream.url}
                />
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={handleOpenDeleteWarning}>
                    <DeleteIcon />
                </IconButton>
            </ListItem>
        </>
    )
}

export default StreamTile;