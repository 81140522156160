import { useState } from "react";
import { Icon, IconButton, Menu, MenuItem } from "@mui/material";
import { getAxios } from "utils";
import WarningModal from "components/WarningModal";
import ContainerFormModal from "./ContainerFormModal";

function ContainerMenu({screenId, container, updateCallBack}) {
    const [edit, setEdit] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteWarning, setDeleteWarning] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => setEdit(true);
    const stopEdit = () => {
        setEdit(false);
        updateCallBack(true);
    }
    
    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/screenContainers';
        const params = { id: container.id }

        try {
            await axiosInstance.delete(uri, { params });
            updateCallBack(true);
            handleCloseDeleteWarning();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const handleOpenDeleteWarning = (event) => {
        event.stopPropagation();
        setDeleteWarning(true);
    }
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Container'
                message={`Tem certeza que deseja deletar o container ${container.name}?`}
            />
            <ContainerFormModal
                open={edit}
                handleClose={stopEdit}
                screenId={screenId}
                container={container}
            />
            <IconButton onClick={handleClick}>
                <Icon>more_vert</Icon>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}>
                    <Icon>edit</Icon>
                    Editar
                </MenuItem>
                <MenuItem onClick={handleOpenDeleteWarning}>
                    <Icon>delete</Icon>
                    Remover
                </MenuItem>
            </Menu>
        </>
    )
}

export default ContainerMenu;