import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import DefaultCheckField from 'components/DefaultCheckField';

function AssetTile ({asset, onChange}) {
    return (
        <TableRow>
            <TableCell scope='row'>
                <DefaultCheckField 
                    checked={asset.belongs != 0} 
                    onChange={(e) => onChange(asset.id, e.target.checked)} 
                />
            </TableCell>
            <TableCell scope='row'>{asset.name}</TableCell>
        </TableRow>
    )
}

export default AssetTile;