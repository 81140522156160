import React, { useState } from 'react';

import { TableCell, TableRow } from "@mui/material";
import PlanModal from '../PlanModal';

function PlanTile({ plan, updatedCallback }) {
    const [open, setOpen] = useState(false);
    const { idOption, name, type, priceDetail } = plan
    const { setup } = priceDetail
    const typeName = window.stxConfig.planTypes.find(
        ({value}) => value == type
    )?.label ?? 'Não definido';
    const price = setup.toLocaleString(
        undefined, {style: "currency", currency: "BRL"}
    )

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    };

    return (
        <>
            <PlanModal open={open} handleClose={handleClose} plan={plan} />
            <TableRow onClick={handleOpen} style={{ cursor: 'pointer' }}>
                <TableCell scope="row">{idOption}</TableCell>
                <TableCell scope="row">{name}</TableCell>
                <TableCell scope="row">{typeName}</TableCell>
                <TableCell scope="row">{price}</TableCell>
            </TableRow>
        </>
    )
}

export default PlanTile;