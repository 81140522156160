import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import { 
    Card, Table, TableBody, TableRow, TableCell, TablePagination 
} from "@mui/material";

import { getAxios } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';
import EmailTile from "./components/EmailHistoryTile";
import SearchBar from "components/SearchBar";
import TablePaginationActions from "components/TablePaginationActions";

const maxEmails = 10;

function EmailHistory() {
    const [emailHistory, setEmailHistory] = useState([]);
    const [totalEmails, setTotalEmails] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);

    const navigate = useNavigate();
    
    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleGetEmailHistory = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/emailHistory";
        let response;
        const params = {
            limitStart: pageAssets*maxEmails, 
            limit: maxEmails,
            search
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            console.log(err)

            if (err.response.status === 404) {
                setErro('');
                setEmailHistory([]);
                setTotalEmails(0)
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setEmailHistory(response.data.result.emailHistory);
        setTotalEmails(response.data.result.total)
    }

    useEffect(async() => { 
        await handleGetEmailHistory() 
        setUpdate(false);
    }, [update, pageAssets, search]);
    
    const handleSearch = async (value) => {
        setSearch(value);
        setPageAssets(0);
    } 

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Histórico de Emails
        </MDTypography>
    </MDBox>

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>Id</strong></TableCell>
                                    <TableCell><strong>Destinatário</strong></TableCell>
                                    <TableCell><strong>Remetente</strong></TableCell>
                                    <TableCell><strong>Assunto</strong></TableCell>
                                    <TableCell><strong>Data</strong></TableCell>
                                    <TableCell width="5"></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{emailHistory.map((email, index) =>
                                <EmailTile
                                    key={`email_${index}`}
                                    email={email}
                                    updatedCallback={setUpdate}
                                />
                            )}</TableBody>
                        </Table>
                        {isLoading &&
                            <BarLoader
                                color="#ACC0F0" 
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination 
                            rowsPerPageOptions={[maxEmails]}
                            component="div"
                            count={totalEmails}
                            rowsPerPage={maxEmails}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default EmailHistory;
