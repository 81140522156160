import React, { useState } from "react";

import {
    Dialog, DialogContent, DialogTitle, IconButton, Icon, Box, Tabs, Tab
} from "@mui/material";
import CatalogFormModal from "../CatalogFormModal";
import HierarchyTabPanel from "../HierarchTabPanel";

function CatalogModal({ open, handleClose, catalog }) {
    const [edit, setEdit] = useState(false);
    const [page, setPage] = useState(0);
    const { id, name } = catalog;

    const handleChangePage = (_, newPage) => setPage(newPage);

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle display='flex' justifyContent='space-between'>
                {name}
                <IconButton onClick={handleEdit}>
                    <Icon>edit</Icon>
            </IconButton>
            </DialogTitle>
            <DialogContent>
                <CatalogFormModal
                    open={edit}
                    handleClose={handleStopEdit}
                    catalog={catalog}
                />
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={page} onChange={handleChangePage} aria-label="basic tabs example">
                        <Tab label="Hierarchy" />
                    </Tabs>
                </Box>
                <HierarchyTabPanel value={page} catalogId={id} index={0} onClose={handleClose} />
            </DialogContent>
        </Dialog>
    )
}

export default CatalogModal;
