import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function WarningModal({
    open, handleClose, handleAccept, handleDismiss, title, message
}) {
  return (
    <Dialog
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDismiss}>Não</Button>
            <Button onClick={handleAccept} autoFocus>
                Sim
            </Button>
        </DialogActions>
        </Dialog>
  );
}
