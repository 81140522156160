import axios from 'axios';
import decode from "jwt-decode";

import moment from 'moment';

export class Relatorios {
    static operadoras = [
        "CLARO",
        "VIVO",
        "TIM",
        "ALGAR",
        "SERCOMTEL",
        "MVNO'S",
    ];

    static acao = ["Saiu", "Entrou"]

    static devices = [
        "iPhone",
        "Android",
        "Computador (PC)",
        "Site",
    ]
}

export function imageUrl(type, id, formatW, formatH, languageId = window.stxConfig.defaultLanguageId) {
  const url =
      `/proxy/imgdata?type=${type}&objectId=${id}&format_w=${formatW}&format_h=${formatH}&languageId=${languageId}`;
  return `${window.stxConfig.imageBaseUrl}${url}`;
}

export function intToCPF(value) {
    if (value == null) return '';
    
    value = value.toString();
    return value.substring(0, 3) + '.' + 
           value.substring(3, 6) + '.' + 
           value.substring(6, 9) + '-' + 
           value.substring(9, 11);
}

export function isOauth() {
    const token = getAccessToken();

    try {
        const decoded = decode(token);
        return decoded.options.oauth;
    } catch (err) {
        console.error("[x] Invalid format:", err.message);
        return false;
    }
}

export function sqlToDate(date) {
    return moment(date).format('DD/MM/YYYY');
}

export function sqlToDateTime(date) {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

export function dateToSql(date) {
    return moment(date).format('YYYY-MM-DD');
}

export function dateTimeToSql(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function timestampToDateTime(timestamp) {
    return moment.unix(timestamp).format('DD/MM/YYYY HH:mm:ss');
} 

export function timestampToSql(timestamp) {
    return moment(timestamp, 'X').format('YYYY-MM-DD HH:mm:ss');
} 

export function setAccessToken(token) {
    if (token) {
        localStorage.setItem("cliente_accessToken", token);
    }
}

export function getAccessToken() {
    return localStorage.getItem("cliente_accessToken");
}

export function unsetAccesToken() {
    return localStorage.removeItem("cliente_accessToken");
}

export function setRefreshToken(token) {
    if (token) {
        localStorage.setItem("cliente_refreshToken", token);
    }
}
  
export function getRefreshToken() {
    return localStorage.getItem("cliente_refreshToken");
}

export function validateAccessToken() {
    const token = getAccessToken();

    try {
        const payload = decode(token);

        if (payload.iat > payload.exp) {
            return false;
        }
        if (payload.exp < moment().unix()) {
            return false;
        }
        if (payload.iat > moment().unix()) {
            return false;
        }

        return true;
    } catch (err) {
        console.error("[x] Invalid format:", err.message);
        return false;
    }
}

export const SERVICE_URL =
    process.env.REACT_APP_SERVICE_URL ||
    (process.env.NODE_ENV === "production"
    ? "/api/"
    : window.stxConfig.baseUrl);

export function getAxios(headers = {}) {
    const instance = axios.create({
        baseURL: window.stxConfig.baseUrl,
        timeout: 8000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-cportal-rest-version': window.stxConfig.version, 
            'x-cportal-rest-identitykey': window.stxConfig.identityKey,
            'X-cPortal-REST-AuthorityAuthToken': getAccessToken(),
            ...headers
        },
    });

    instance.interceptors.response.use(async function (response) {
        const token = response.data.result?.newAuthToken ?? '';
        setAccessToken(token)
        return response;
    }, function (error) {
        const token = error.response.data.result?.newAuthToken ?? '';
        setAccessToken(token);
        throw error;
    })

    return instance
}