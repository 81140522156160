import React, { useEffect, useState } from "react";
import * as yup from 'yup';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DefaultCheckField from "components/DefaultCheckField";
import DefaultSelectField from "components/DefaultSelectField";
import DefaultSelectFromData from "components/DefaultSelectFromData";

function ContainerFormModal({
    open,
    handleClose,
    screenId,
    container = null,
    order = 0,
}) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(container != null
        ? container.name
        : 'Novo Container'
    );
    const [fields, setFields] = useState([]);

    const initialValues = {
        id: container?.id,
        name: container?.name ?? '',
        designMode: container?.designMode ?? '',
        screenId: container?.screenId ?? screenId,
        order: container?.order ?? order,
        type: container?.type ?? '',
        value: container?.value,
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }
            formData.append(key, value);
        }

        try {
            if (container != null) {
                const uri = '/proxy/admin/screenContainers/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/screenContainers';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        screenId: yup.number().required(),
        designMode: yup.number().required(),
        type: yup.number().required(),
        order: yup.number().required(),
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues,
        onSubmit: saveChanges,
        validationSchema
    });

    useEffect(() => open && setValues(initialValues), [open]);
    
    useEffect(() => {
        if (container != null) setTitle(values.name);
    }, [container, values.name])
    
    useEffect(() => {
        if (values.type != null) {
            const fields = window.stxConfig.screenContainerTypes
                .find(screenContainerType => screenContainerType.value == values.type)
                ?.fields;

            setFields(fields);
        }
    }, [values.type])
    
    const getField = (field, index) => {
        const { fieldType, name, ...args } = field;

        const key = `field_${index}`
        const value = values.value ? values.value[name] : null;
        const formattedName = `value.${name}`;

        switch (fieldType) {
            case 'text':
                return <DefaultTextField
                    key={key}
                    value={value}
                    onChange={handleChange}
                    name={formattedName}
                    {...args}
                />
            case 'select':
                return <DefaultSelectField
                    key={key}
                    value={value}
                    onChange={handleChange}
                    name={formattedName}
                    {...args}
                />
            case 'checkbox':
                return <DefaultCheckField
                    key={key}
                    value={value}
                    onChange={handleChange}
                    name={formattedName}
                    {...args}
                />
            case 'category':
                return <DefaultSelectFromData
                    key={key}
                    open={true}
                    value={value}
                    name={formattedName}
                    uri="/proxy/admin/categories"
                    parser={(result) => result.categories.map(category => ({
                        label: category.name,
                        id: category.id
                    }))}
                    onChange={newValue => {
                        const value = newValue ? newValue.id : '';
                        setFieldValue(formattedName, value)
                    }}
                    {...args}
                />
            case 'asset':
                return <DefaultSelectFromData
                    key={key}
                    open={true}
                    value={value}
                    name={formattedName}
                    uri="/proxy/admin/assets"
                    parser={(result) => result.assets.map(asset => ({
                        label: asset.name,
                        id: asset.id
                    }))}
                    onChange={newValue => {
                        const value = newValue ? newValue.id : '';
                        setFieldValue(formattedName, value)
                    }}
                    {...args}
                />
            default:
                return <></>;
        }
    }

    const handleChangeType = (e, value) => {
        e.preventDefault();

        const type = value.props.value;
        setFieldValue('type', type);

        const fields = window.stxConfig.screenContainerTypes
            .find(screenContainerType => screenContainerType.value == type)
            ?.fields;

        setFieldValue(
            'value',
            Object.fromEntries(fields.map(item => [item.name, item.default ?? '']))
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DefaultTextField
                    label="Nome"
                    value={values.name}
                    name='name'
                    onChange={handleChange}
                    required
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
                
                <DefaultSelectField
                    required
                    value={values.designMode}
                    label='Design Mode'
                    name='designMode'
                    onChange={handleChange}
                    items={window.stxConfig.screenContainerDesigns}
                    error={touched.designMode && Boolean(errors.designMode)}
                    helperText={touched.designMode && errors.designMode}
                />

                <Typography variant="h4" component="h4">
                    Campos Customizáveis
                </Typography>
                <DefaultSelectField
                    required
                    value={values.type}
                    label='Tipo'
                    name='type'
                    onChange={handleChangeType}
                    items={window.stxConfig.screenContainerTypes}
                    error={touched.type && Boolean(errors.type)}
                    helperText={touched.type && errors.type}
                />
                {fields?.map(getField)}
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ContainerFormModal;