import { Box, Button, CircularProgress, Divider, List } from "@mui/material";
import MDBox from "components/MDBox";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAxios } from "utils";
import AddIcon from "@mui/icons-material/Add";
import UserPlanTile from "../UserPlanTile";
import UserPlanFormModal from "../UserPlanFormModal";

function UserPlanTabPanel({ user, value, index }) {
    const navigate = useNavigate();

    const [plans, setPlans] = useState([]);
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);

    const handleGetPlans = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/userOptions";
        let response;
        const params = { userId: user.id }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setPlans(response.data.result.options);
    }

    useEffect(() => { 
        handleGetPlans(); 
        setUpdate(false);
    }, [user, update])

    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        setUpdate(true);
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <MDBox p={ 3 }>
                    <UserPlanFormModal 
                        open={open} 
                        handleClose={handleClose} 
                        userId={user.id}
                    /> 
                    <Box
                        display='flex'
                        justifyContent='flex-end'
                    >
                        <Button 
                            variant="contained" 
                            style={{color: 'white'}}
                            onClick={handleOpen}
                            startIcon={<AddIcon />}
                        >
                            Adicionar
                        </Button>
                    </Box>
                    {loading 
                        ? <CircularProgress />
                        : <List>{plans.map((plan, index) => 
                            <Fragment key={`plan_${index}`}>
                                <UserPlanTile
                                    plan={plan} 
                                    userId={user.id} 
                                    updateCallBack={setUpdate}
                                />
                                {index < plans.length - 1 && <Divider />}
                            </Fragment> 
                        )}</List>
                    }
                </MDBox>
            )}
        </div>
    )

}

export default UserPlanTabPanel;