import React, { useState } from "react";
import moment from "moment";

import { Icon, IconButton, TableCell, TableRow } from "@mui/material";
import UserModal from "../UserModal";
import WarningModal from "components/WarningModal";
import { getAxios } from "utils";

function UserTile({ user, updatedCallback }) {
    const [open, setOpen] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);

    const { status, name, lastName, email, dob, type } = user;
    const fullName = name + ' ' + (lastName ?? '');
    const birthday = moment(dob).format('DD/MM/YYYY');
    const statusValue = status ? 'Ativo' : 'Inativo';
    const typeName = window.stxConfig.userTypes.find(({ value }) => value != 0 && value == type)?.label ?? 'Não definido';

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    };

    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/crm/admin/users';
        const params = {
            id: user.id
        }

        try {
            await axiosInstance.delete(uri, { params });
            updatedCallback(true);
            handleCloseDeleteWarning();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const handleOpenDeleteWarning = (event) => {
        event.stopPropagation();
        setDeleteWarning(true);
    }
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Usuário'
                message={`Tem certeza que deseja deletar o usuário ${fullName}?`}
            />
            <UserModal user={user} open={open} handleClose={handleClose} />
            <TableRow onClick={handleOpen} style={{ cursor: 'pointer' }}>
                <TableCell scope="row">{fullName}</TableCell>
                <TableCell scope="row">{email}</TableCell>
                <TableCell scope="row">{birthday}</TableCell>
                <TableCell scope="row">{statusValue}</TableCell>
                <TableCell scope="row">{typeName}</TableCell>
                <TableCell scope="row">
                    <IconButton onClick={handleOpenDeleteWarning}>
                        <Icon>delete</Icon>
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}

export default UserTile;
