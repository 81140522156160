import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Card, CardContent, CardHeader, Icon } from "@mui/material";
import { useParams } from "react-router-dom";
import ContainerMenu from "./ContainerMenu";
import Container from "./Container";
import ListContent from "./ListContent";
import History from "./History";

function ScreenContainer({ container, updateCallBack }) {
    const { id: screenId } = useParams();

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
    } = useSortable({
        id: container.id,
        data: { type: "Container", container }
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };

    const cardStyle = {
        border: 1
    }

    let createContainer = () => {
        switch (container.type) {
            case 1:
                return <Container
                    container={container}
                    updateCallBack={updateCallBack}
                />
            case 2:
                return <ListContent
                    container={container}
                />
            case 3:
                return <History
                    container={container}
                />
        }
    }

    return (
        <div ref={setNodeRef} style={style}>
            <Card sx={cardStyle}>
                <CardHeader
                    title={
                        <Box display='flex'>
                            <div {...listeners} {...attributes}>
                                <Icon>drag_indicator</Icon>
                            </div>
                            {container.name}
                        </Box>
                    }
                    action={<ContainerMenu
                        container={container}
                        screenId={screenId}
                        updateCallBack={updateCallBack}
                    />}
                />

                <CardContent>{createContainer()}</CardContent>
            </Card>
        </div>
    );
}

export default ScreenContainer;