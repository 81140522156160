// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavMenu
import {
    collapseItem,
    collapseIconBox,
    collapseIcon,
    collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Box, Collapse, ListItemButton } from "@mui/material";
import SidenavCollapse from "./SidenavCollapse";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function SidenavMenu({ icon, name, active, items, collapseName, margin_left = 0, space, ...rest }) {
    const [controller] = useMaterialUIController();
    const [open, setOpen] = useState();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

    const handleClick = () => setOpen(!open);

    return (
        <Box style={{ marginLeft: space }}>
            <ListItemButton
                component="li"
                onClick={handleClick}
                style={{ padding: 0, margin_left }}
            >
                <MDBox
                    {...rest}
                    sx={(theme) =>
                        collapseItem(theme, {
                            active,
                            transparentSidenav,
                            whiteSidenav,
                            darkMode,
                            sidenavColor,
                        })
                    }
                >
                    <ListItemIcon
                        sx={(theme) =>
                            collapseIconBox(theme, {
                                transparentSidenav,
                                whiteSidenav,
                                darkMode,
                                active
                            })
                        }
                    >
                        {typeof icon === "string"
                            ? <Icon sx={(theme) => collapseIcon(theme, { active })}>
                                {icon}
                            </Icon>
                            : icon
                        }
                    </ListItemIcon>

                    <ListItemText
                        primary={name}
                        sx={(theme) =>
                            collapseText(theme, {
                                miniSidenav,
                                transparentSidenav,
                                whiteSidenav,
                                active,
                            })
                        }
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </MDBox>
            </ListItemButton>
            <Collapse in={open}>
                {items.map(({ name, icon, key, route, collapse }) =>
                    collapse != null && collapse.length !== 0
                        ? <SidenavMenu
                            key={key}
                            icon={icon}
                            name={name}
                            collapseName={collapseName}
                            active={collapseName?.includes(key)}
                            items={collapse}
                            space={space + 10}
                        />
                        : <Box style={{ marginLeft: space + 10 }}>
                            <NavLink key={key} to={route} >
                                <SidenavCollapse
                                    key={key}
                                    name={name}
                                    icon={icon}
                                    active={collapseName?.includes(key)}
                                />
                            </NavLink>
                        </Box>
                )}
            </Collapse>
        </Box>
    );
}

// Setting default values for the props of SidenavMenu
SidenavMenu.defaultProps = {
    active: false,
    space: 0
};

// Typechecking props for the SidenavMenu
SidenavMenu.propTypes = {
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,
    items: PropTypes.array,
    collapseName: PropTypes.string.isRequired
};

export default SidenavMenu;
