import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import { 
    Card, Table, TableBody, TableRow, TableCell, Button, TablePagination, Box, Icon 
} from "@mui/material";

import { getAxios } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';
import SenderTile from "./components/SenderTile";
import SenderFormModal from "./components/SenderFormModal";
import SearchBar from "components/SearchBar";

const maxSenders = 10;

function EmailSenders() {
    const [senders, setSenders] = useState([]);
    const [totalSenders, setTotalSenders] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    
    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleGetSenders = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/emailSenders";
        let response;
        const params = { 
            limitStart: pageAssets*maxSenders, 
            limit: maxSenders,
            search
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            if (err.response.data.error.code == 404) {
                setSenders([]);
                setTotalSenders(0);
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setSenders(response.data.result.senders);
        setTotalSenders(response.data.result.total)
    }

    useEffect(async() => { 
        await handleGetSenders() 
        setUpdate(false)
    }, [update, pageAssets, search]);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        setUpdate(true);
    }

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Email Senders
        </MDTypography>
    </MDBox>

    const handleSearch = async (value) => {
        setSearch(value);
        setPageAssets(0);
    } 

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SenderFormModal open={open} handleClose={handleClose} />
            <Box
                display='flex'
                justifyContent='flex-end'
            >
                <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={handleOpen}
                    startIcon={<Icon>add</Icon>}
                >
                    Adicionar
                </Button>
            </Box>
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>ID</strong></TableCell>
                                    <TableCell><strong>Nome</strong></TableCell>
                                    <TableCell><strong>Host</strong></TableCell>
                                    <TableCell><strong>Porta</strong></TableCell>
                                    <TableCell><strong>Usuário</strong></TableCell>
                                    <TableCell><strong>Remetente</strong></TableCell>
                                    <TableCell width="5"></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{senders.map((sender, index) =>
                                <SenderTile
                                    key={`sender_${index}`}
                                    sender={sender}
                                    updatedCallback={setUpdate}
                                />
                            )}</TableBody>
                        </Table>
                        {isLoading &&
                            <BarLoader
                                color="#ACC0F0" 
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination 
                            rowsPerPageOptions={[maxSenders]}
                            component="div"
                            count={totalSenders}
                            rowsPerPage={maxSenders}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default EmailSenders;
