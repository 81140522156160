import React, { useEffect, useState } from "react";
import * as yup from 'yup';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Box
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DefaultCheckField from "components/DefaultCheckField";
import DefaultSelectField from "components/DefaultSelectField";
import ImageField from "components/ImageField";
import { imageUrl } from "utils";

function ChannelFormModal({ open, handleClose, channel = null }) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(channel != null
        ? channel.name
        : 'Novo Canal'
    );

    const initialValues = {
        id: channel?.id ?? '',
        name: channel?.name ?? '',
        allowRecording: channel?.allowRecording ?? false,
        allowTimeshift: channel?.allowTimeshift ?? false,
        channelDefinition: channel?.channelDefinition ?? '',
        isFta: channel?.isFta ?? false,
        isRadio: channel?.isRadio ?? false,
        mainGenre: channel?.mainGenre ?? '',
        moralityLevel: channel?.moralityLevel ?? 0,
        catchup: channel?.catchup ?? false,
        startOver: channel?.startOver ?? false,
        timeshift: channel?.timeshift ?? '',
        parentalControl: channel?.parentalControl ?? false,
        synopsis: channel?.synopsis ?? '',
        thumbImages: [],
        thumb: '',
        coverImages: [],
        cover: ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();

        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (channel != null) {
                const uri = '/proxy/admin/channels/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/channels';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        parentalControl: yup.bool(),
        moralityLevel: yup
            .number()
            .when('parentalControl', {
                is: parentalControl => parentalControl,
                then: yup
                    .number()
                    .max(21, 'Idade máxima não pode ser maior que 21')
                    .min(1, 'Idade máxima não pode ser menor que 1')
            }),
        mainGenre: yup.string().required(),
        channelDefinition: yup.string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue
    } = useFormik({
        initialValues,
        onSubmit: saveChanges,
        validationSchema,
    });

    useEffect(() => {
        if (channel != null) setTitle(values.name);
    }, [channel, values.name])

    useEffect(() => { if (open) resetForm(); }, [open])

    const onChangeThumb = (image) => {
        setFieldValue('thumbImages', image);
        setFieldValue('thumb', image[0]?.file)
    }

    const onChangeCover = (image) => {
        setFieldValue('coverImages', image);
        setFieldValue('cover', image[0]?.file)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <ImageField
                    label="Thumb"
                    value={values.thumbImages}
                    onChange={onChangeThumb}
                    initialValue={channel?.thumb && imageUrl(
                        window.stxConfig.imageTypes.tvChannel,
                        channel.id, 50, 50
                    )}
                    width={50}
                    height={50}
                />
                <Box mt={2}>
                    <ImageField
                        label="Cover"
                        value={values.coverImages}
                        onChange={onChangeCover}
                        initialValue={channel?.cover && imageUrl(
                            window.stxConfig.imageTypes.tvChannelPlaceholder,
                            channel.id, 380, 192
                        )}
                        width={380}
                        height={192}
                    />
                </Box>
                <DefaultTextField
                    required
                    label="Nome"
                    value={values.name}
                    name='name'
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
                <DefaultTextField
                    label="Descrição"
                    value={values.synopsis}
                    name='synopsis'
                    multiline={true}
                    onChange={handleChange}
                />
                <DefaultTextField
                    required
                    label="Gênero principal"
                    value={values.mainGenre}
                    name='mainGenre'
                    onChange={handleChange}
                    error={touched.mainGenre && Boolean(errors.mainGenre)}
                    helperText={touched.mainGenre && errors.mainGenre}
                />
                <DefaultSelectField
                    required
                    value={values.channelDefinition}
                    label='Definição'
                    name='channelDefinition'
                    onChange={handleChange}
                    items={window.stxConfig.channelDefinitions}
                    error={touched.channelDefinition && Boolean(errors.channelDefinition)}
                    helperText={touched.channelDefinition && errors.channelDefinition}
                />
                <DefaultCheckField
                    required
                    label="Permitir gravação?"
                    name="allowRecording"
                    checked={values.allowRecording}
                    onChange={handleChange}
                />
                <DefaultCheckField
                    required
                    label="Permitir retroceder?"
                    name="allowTimeshift"
                    checked={values.allowTimeshift}
                    onChange={handleChange}
                />
                {values.allowTimeshift &&
                    <DefaultTextField
                        required
                        label="Tempo máximo para retroceder"
                        value={values.timeshift}
                        name='timeshift'
                        onChange={handleChange}
                        type='number'
                        error={touched.timeshift && Boolean(errors.timeshift)}
                        helperText={touched.timeshift && errors.timeshift}
                    />
                }
                <DefaultCheckField
                    required
                    label="É FTA?"
                    name="isFta"
                    checked={values.isFta}
                    onChange={handleChange}
                />
                <DefaultCheckField
                    required
                    label="É radio?"
                    name="isRadio"
                    checked={values.isRadio}
                    onChange={handleChange}
                />
                <DefaultCheckField
                    label="Controle Parental?"
                    required
                    name="parentalControl"
                    checked={values.parentalControl}
                    onChange={handleChange}
                />
                {values.parentalControl &&
                    <DefaultTextField
                        label="Idade máxima"
                        value={values.maximumMoralityLevel}
                        name='maximumMoralityLevel'
                        onChange={handleChange}
                        error={touched.maximumMoralityLevel && Boolean(errors.maximumMoralityLevel)}
                        helperText={touched.maximumMoralityLevel && errors.maximumMoralityLevel}
                        type="number"
                    />
                }
                <DefaultCheckField
                    required
                    label="Chatchup?"
                    name="catchup"
                    checked={values.catchup}
                    onChange={handleChange}
                />
                <DefaultCheckField
                    required
                    label="Pode iniciar pelo epg?"
                    name="startOver"
                    checked={values.startOver}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChannelFormModal;
