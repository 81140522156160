import React, { useState } from "react";

import { Icon, IconButton, TableCell, TableRow } from "@mui/material";
import WarningModal from "components/WarningModal";
import { getAxios } from "utils";
import EmailHistoryModal from "../EmailHistoryModal";
import moment from "moment";

function EmailTile({ email, updatedCallback }) {
    const [open, setOpen] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);

    const { id, receiver, subject, senderName, date } = email;
    const formattedDate = moment(date).format('DD/MM/YYYY HH:mm:ss')

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    }
    
    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/emailHistory';
        const params = { id }

        try {
            await axiosInstance.delete(uri, { params });
            updatedCallback(true);
            handleCloseDeleteWarning();
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }
    
    const handleOpenDeleteWarning = (event) => {
        event.stopPropagation();
        setDeleteWarning(true);
    }
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Email do histórico'
                message={`Tem certeza que deseja deletar este email do histórico?`}
            />
            <EmailHistoryModal open={open} handleClose={handleClose} email={email} />
            <TableRow style={{ cursor: 'pointer' }} onClick={handleOpen}>
                <TableCell scope="row">{id}</TableCell>
                <TableCell scope="row">{receiver}</TableCell>
                <TableCell scope="row">{senderName}</TableCell>
                <TableCell scope="row">{subject}</TableCell>
                <TableCell scope="row">{formattedDate}</TableCell>
                <TableCell scope="row">
                    <IconButton onClick={handleOpenDeleteWarning}>
                        <Icon>delete</Icon>
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}

export default EmailTile;
