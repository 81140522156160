import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import TitleValue from "components/TitleValue";
import moment from "moment";

function NotificationModal({ open, onClose, notification }) {
    const { title, body, topic, date } = notification
    const formattedDate = moment(date).format('D/MM/YYYY H:m:s')

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TitleValue title="Titulo" value={title} />
                <TitleValue title="Data" value={formattedDate} />
                <Typography variant="h4">Mensagem</Typography>
                <span style={{whiteSpace: "pre-line"}}>
                    {body}
                </span>
            </DialogContent>
        </Dialog>
    )
}

export default NotificationModal;