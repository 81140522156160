import { IconButton, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAxios } from 'utils';
import WarningModal from 'components/WarningModal';
import { useNavigate } from 'react-router-dom';

function ChannelTile ({ channel, planId, updateCallBack }) {
    const navigate = useNavigate();

    const [deleteWarning, setDeleteWarning] = useState(false);
    
    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/liveChannels';
        const params = {
            optionId: planId,
            id: channel.id
        }

        try {
            await axiosInstance.delete(uri, { params });
            updateCallBack(true);
            handleCloseDeleteWarning();
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const handleOpenDeleteWarning = () => setDeleteWarning(true);
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Remover Canal'
                message={`Tem certeza que deseja remover o pacote ${channel.name}?`}
            />
            <ListItem>
                <ListItemText primary={channel.name} />
                <IconButton onClick={handleOpenDeleteWarning}>
                    <DeleteIcon />
                </IconButton>
            </ListItem>
        </>
    )
}

export default ChannelTile;