import React, { useState } from "react";

import { Icon, IconButton, TableCell, TableRow } from "@mui/material";
import TemplateFormModal from "../TemplateFormModal";
import WarningModal from "components/WarningModal";
import { getAxios } from "utils";

function TemplateTile({ template, updatedCallback }) {
    const [open, setOpen] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);

    const { id, name, subject, branchName, key } = template;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    }
    
    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/emailTemplates';
        const params = { id }

        try {
            await axiosInstance.delete(uri, { params });
            updatedCallback(true);
            handleCloseDeleteWarning();
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }
    
    const handleOpenDeleteWarning = (event) => {
        event.stopPropagation();
        setDeleteWarning(true);
    }
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Template'
                message={`Tem certeza que deseja deletar o template ${name}?`}
            />
            <TemplateFormModal open={open} handleClose={handleClose} template={template} />
            <TableRow style={{ cursor: 'pointer' }} onClick={handleOpen}>
                <TableCell scope="row">{id}</TableCell>
                <TableCell scope="row">{name}</TableCell>
                <TableCell scope="row">{subject}</TableCell>
                <TableCell scope="row">{branchName}</TableCell>
                <TableCell scope="row">{key}</TableCell>
                <TableCell scope="row">
                    <IconButton onClick={handleOpenDeleteWarning}>
                        <Icon>delete</Icon>
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}

export default TemplateTile;
