import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material"

function DefaultSelectField(props) {
    const { label, items, children, sx, helperText, ...other } = props;

    return (
        <FormControl
            variant="standard"
            sx={{ mb: 2, mt: 2, minWidth: 120, ...sx }}
            {...other}
        >
            <InputLabel>{label}</InputLabel>
            <Select fullWidth {...other} >
                {items.filter(item => !item.hidden).map((item, index) =>
                    <MenuItem
                        disabled={item.disabled}
                        value={item.value}
                        key={index}
                    >
                        {item.label}
                    </MenuItem>
                )}
                {children}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}

export default DefaultSelectField;
