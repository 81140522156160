import React, { useState } from "react";
import PropTypes from "prop-types";

import { Dialog, DialogContent, DialogTitle, Icon, IconButton, Typography } from "@mui/material";
import TitleValue from "components/TitleValue";
import BranchFormModal from "../BranchFormModal";

function BranchModal({ open, handleClose, branch }) {
    const [edit, setEdit] = useState(false);

    const { name, oauth, payment, profiles } = branch
    const { endpoint, clientId, clientSecret } = oauth;

    const oauthSection = (
        <>
            <Typography>Oauth</Typography>
            <TitleValue
                title="url"
                value={endpoint}
            />
            <TitleValue
                title="clientId"
                value={clientId}
            />
            <TitleValue
                title="clientSecret"
                value={clientSecret}
            />
        </>
    );

    const paymentSection = (
        <>
            <Typography>Pagamento</Typography>
            <TitleValue
                title="url"
                value={payment.url}
            />
            <TitleValue
                title="id"
                value={payment.id}
            />
            <TitleValue
                title="hash"
                value={payment.hash}
            />
            <TitleValue
                title="confirmHash"
                value={payment.confirmHash}
            />
        </>
    );

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            scroll="paper"
        >
            <BranchFormModal handleClose={handleStopEdit} open={edit} branch={branch} />
            <DialogTitle display='flex' justifyContent='space-between'>
                {name}
                <IconButton onClick={handleEdit}>
                    <Icon>edit</Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TitleValue
                    title="Máximo de perfis"
                    value={profiles}
                />
                {endpoint && oauthSection}
                {payment.url && paymentSection}
            </DialogContent>
        </Dialog>
    )
}

BranchModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default BranchModal;
