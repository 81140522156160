import React, { useState } from 'react';

import { TableCell, TableRow } from "@mui/material";
import NotificationModal from '../NotificationModal';
import moment from 'moment';

function NotificationTile({ notification, updatedCallback }) {
    const [open, setOpen] = useState(false);
    const { title, topic, date } = notification;
    const formattedDate = moment(date).format('D/MM/YYYY H:m:s')
    

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    };

    return (
        <>
            <NotificationModal open={open} onClose={handleClose} notification={notification} />
            <TableRow onClick={handleOpen} style={{ cursor: 'pointer' }}>
                <TableCell scope="row">{title}</TableCell>
                <TableCell scope="row">{formattedDate}</TableCell>
            </TableRow>
        </>
    )
}

export default NotificationTile;