import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { getAxios } from "utils";
import Category from "../ScreenItem/Category";
import Asset from "../ScreenItem/Asset";

function ListContent({ container }) {
    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);

    const gap = 10;

    const designModeValue = window
        .stxConfig
        .screenContainerDesigns
        .find(design => design.value == container.designMode);

    const handleListContent = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/listContent";
        let response;
        const params = { id: container.value?.category ?? 0 }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        let contents = response.data.result.contents;
        setContents(contents);
        setLoading(false);
    }

    useEffect(() => handleListContent(), [container]);

    let createContent = (content, index) => {
        const key = `content_${index}`;

        switch (content.type) {
            case 'category':
                return <Category
                    key={key}
                    id={content.category?.id}
                    designMode={designModeValue}
                />;
            case 'asset':
                return <Asset
                    key={key}
                    id={content.asset?.id}
                    designMode={designModeValue}
                />;
            default:
                return <></>;
        }
    }

    return (
        <Box display='flex' overflow='auto' gap={`${gap}px`}>
            {loading 
                ? <CircularProgress color="inherit" size={20} /> 
                : contents.map(createContent)
            }
        </Box>
    );
}

export default ListContent;