import { Box, Button, Typography } from "@mui/material"
import MDBox from "components/MDBox";
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import ChannelFormModal from "../ChannelFormModal";
import TitleValue from "components/TitleValue";
import { imageUrl } from "utils";

function ChannelDataTabPanel({ value, index, onClose, channel }) {
    const [edit, setEdit] = useState(false);
    const {
        id, name, allowRecording, allowTimeshift, channelDefinition,
        isFta, isRadio, mainGenre, moralityLevel, catchup,
        startOver, timeshift, parentalControl, synopsis, cover, thumb
    } = channel;

    const thumbUrl = imageUrl(
        window.stxConfig.imageTypes.tvChannel,
        id, 50, 50
    );

    const coverUrl = imageUrl(
        window.stxConfig.imageTypes.tvChannelPlaceholder,
        id, 380, 192
    );

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        onClose();
    };

    return (
        <div hidden={value !== index}>
            {value === index && (
                <MDBox sx={{ p: 3 }}>
                    <ChannelFormModal
                        open={edit}
                        handleClose={handleStopEdit}
                        channel={channel}
                    />

                    {thumb && (<>
                        <Typography variant="h4">Thumb</Typography>
                        <img src={thumbUrl} alt="" width="50" height="50" />
                    </>)}

                    {cover && (<>
                        <Typography variant="h4">Cover</Typography>
                        <img src={coverUrl} alt="" width="380" height="192" />
                    </>)}
                    <br />

                    <TitleValue title="Nome" value={name} />
                    <TitleValue title="Descrição" value={synopsis} />
                    <TitleValue title="Gênero Principal" value={mainGenre} />
                    <TitleValue title="Definição" value={channelDefinition} />
                    <TitleValue title="É radio?" value={isRadio ? 'Sim' : 'Não'} />
                    <TitleValue title="É FTA?" value={isFta ? 'Sim' : 'Não'} />
                    <TitleValue title="Permite gravação?" value={allowRecording ? 'Sim' : 'Não'} />
                    <TitleValue title="Permite retroceder?" value={allowTimeshift ? 'Sim' : 'Não'} />
                    {allowTimeshift &&
                        <TitleValue title="Tempo máximo para retroceder" value={timeshift} />
                    }
                    <TitleValue title="Controle Parental?" value={parentalControl ? 'Sim' : 'Não'} />
                    {parentalControl &&
                        <TitleValue title="Idade mínima" value={moralityLevel} />
                    }
                    <TitleValue title="Permite inicio pelo EPG?" value={startOver ? 'Sim' : 'Não'} />
                    <TitleValue title="Catchup?" value={catchup ? 'Sim' : 'Não'} />

                    <Box display='flex' justifyContent='flex-end'>
                        <Button
                            variant="contained"
                            style={{ color: 'white' }}
                            onClick={handleEdit}
                            startIcon={<EditIcon />}
                        >
                            Editar
                        </Button>
                    </Box>
                </MDBox>
            )}
        </div>
    )
}

export default ChannelDataTabPanel;