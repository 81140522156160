import React, { useState } from "react";

import { TableCell, TableRow } from "@mui/material";
import BranchModal from "../BranchModal";

function BranchTile({ branch, updatedCallback }) {
    const [open, setOpen] = useState(false);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        updatedCallback(true);
    };

    return (
        <>
            <BranchModal branch={branch} open={open} handleClose={handleClose} />
            <TableRow onClick={handleOpen} style={{cursor: 'pointer'}}>
                <TableCell scope="row">{branch.id}</TableCell> 
                <TableCell scope="row">{branch.name}</TableCell> 
            </TableRow>
        </>
    )
}

export default BranchTile;
