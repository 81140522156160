import { Box, Button, Grid } from "@mui/material"
import MDBox from "components/MDBox";
import UserFormModal from "../UserFormModal";
import moment from "moment";
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import TitleValue from "components/TitleValue";
import { isOauth } from "utils";
import { intToCPF } from "utils";

function UserDataTabPanel ({user, value, index, onClose}) {
    const [open, setOpen] = useState(false);

    const PersonalSection = <Grid container columnSpacing={2}>
        <Grid item xs>
            <TitleValue 
                title="Nome" 
                value={user.name}
            />
        </Grid>
        <Grid item xs>
            <TitleValue 
                title="Sobrenome" 
                value={user.lastName}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue 
                title="Email" 
                value={user.email}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue 
                title="Telefone"
                value={user.phone}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue 
                title="CPF" 
                value={intToCPF(user.cpf)}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue 
                title="Data de Nascimento" 
                value={moment(user.dob).format('DD/MM/YYYY')}
            />
        </Grid>
    </Grid>

    const AddressSection = <Grid container columnSpacing={2}>
        <Grid item xs>
            <TitleValue
                title="Endereço"
                value={user.address}
            />
        </Grid>
        <Grid item xs>
            <TitleValue
                title="Número"
                value={user.addressNumber}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Complemento"
                value={user.address2}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="CEP"
                value={user.cep}
            />
        </Grid>
        <Grid item>
            <TitleValue
                title="Cidade"
                value={user.city}
            />
        </Grid>
        <Grid item>
            <TitleValue
                title="Estado"
                value={user.state}
            />
        </Grid>
        <Grid item>
            <TitleValue
                title="País"
                value={user.country}
            />
        </Grid>
    </Grid>;
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        onClose();
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <MDBox sx={{ p: 3 }}>
                    <UserFormModal
                        open={open}
                        handleClose={handleClose}
                        user={user}
                    />
                    <MDBox mb={1}>
                        {PersonalSection}
                    </MDBox>

                    <MDBox>
                        {AddressSection}
                    </MDBox>
                    
                    <MDBox mt={2}>
                        <TitleValue
                            title="Tipo"
                            value={window.stxConfig.userTypes.find(({value}) => value != 0 && value == user.type)?.label ?? 'Não definido'}
                        />
                    </MDBox>

                    { !isOauth() &&
                        <Box display='flex' justifyContent='flex-end'>
                            <Button 
                                variant="contained" 
                                style={{color: 'white'}}
                                onClick={handleOpen}
                                startIcon={<EditIcon />}
                            >
                                Editar
                            </Button>
                        </Box>
                    }
                </MDBox>
            )}
        </div>
    )

}

export default UserDataTabPanel;