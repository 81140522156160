import React, { useEffect, useState } from "react";
import * as yup from 'yup';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography 
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DefaultSelectField from "components/DefaultSelectField";
import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";
import TitleValue from "components/TitleValue";

function TemplateFormModal({ open, handleClose, template = null }) {
    const navigate = useNavigate();
    
    const [branches, setBranches] = useState([]);
    const [loadingBranches, setLoadingBranches] = useState([]);
    const [senders, setSenders] = useState([]);
    const [loadingSenders, setLoadingSenders] = useState([]);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState(template != null
        ? template.name
        : 'Novo Template'
    );

    const initialValues = {
        id: template?.id ?? '', 
        name: template?.name ?? '', 
        subject: template?.subject ?? '',
        branch: template?.branch ?? '', 
        senderId: template?.senderId ?? '', 
        content: template?.content ?? '', 
        key: template?.key ?? ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();

        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (template != null) {
                const uri = '/proxy/admin/emailTemplates/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/emailTemplates';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        subject: yup.string().required(),
        branch: yup.number().required(),
        senderId: yup.number().required(),
        content: yup.string().required(),
        key: yup.string().required(),
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
    } = useFormik({
        initialValues,
        onSubmit: saveChanges,
        validationSchema,
    });

    useEffect(() => {
        if (template != null) setTitle(values.name);
    }, [template, values.name])

    useEffect(() => { if (open) resetForm(); }, [open])
    
    const handleGetBranches = async () => {
        setLoadingBranches(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/branches";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não encontrado")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let branchesAux = response.data.result.branches.map(branch => ({
            label: branch.name,
            id: branch.id
        }));
        setBranches(branchesAux);
        setLoadingBranches(false);
    }
    
    const handleGetSenders = async () => {
        setLoadingSenders(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/emailSenders";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não encontrado")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let sendersAux = response.data.result.senders.map(sender => ({
            label: sender.name,
            id: sender.id
        }));
        setSenders(sendersAux);
        setLoadingSenders(false);
    }

    useEffect(() => {
        if (open) {
            handleGetBranches();
            handleGetSenders();
        }
    }, [open]);
    
    useEffect(() => { if (open) resetForm() }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DefaultTextField
                    required
                    label="Nome"
                    value={values.name}
                    name='name'
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
                <DefaultTextField
                    required
                    label="Assunto"
                    value={values.subject}
                    name='subject'
                    onChange={handleChange}
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject}
                />
                {!loadingBranches &&
                    <DefaultAutoCompleteField
                        label="Branch"
                        value={values.branch}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('branch', value)
                        }}
                        options={branches}
                        error={touched.branch && Boolean(errors.branch)}
                        helperText={touched.branch && errors.branch}
                    />
                }
                {!loadingSenders &&
                    <DefaultAutoCompleteField
                        label="Email Sender"
                        value={values.senderId}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('senderId', value)
                        }}
                        options={senders}
                        error={touched.senderId && Boolean(errors.senderId)}
                        helperText={touched.senderId && errors.senderId}
                    />
                }
                <DefaultSelectField
                    required
                    value={values.key}
                    label='Chave'
                    name='key'
                    onChange={handleChange}
                    items={window.stxConfig.eventKeys}
                    error={touched.key && Boolean(errors.key)}
                    helperText={touched.key && errors.key}
                />
                <CKEditor
                    editor={ClassicEditor}
                    data={values.content}
                    onChange={(_, editor) => {
                        const data = editor.getData();
                        setFieldValue('content', data);
                    }}
                />
                
                {values.key != '' &&
                    <>
                        <Typography>Legenda</Typography>
                        {window.stxConfig.eventKeys
                            .find(key => key.value == values.key)
                            ?.captions
                            .map(value =>
                                <TitleValue title={value.title} value={value.description} />
                            )
                        }
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TemplateFormModal;
