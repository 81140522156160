import { Box, Dialog, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material"
import { useState } from "react";
import ChannelDataTabPanel from "./ChannelDataTabPanel";
import StreamTabPanel from "./StreamTabPanel";

function ChannelModal({ open, handleClose, channel }) {
    const [page, setPage] = useState(0);
    const handleChangePage = (_, newPage) => setPage(newPage);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>{channel.name}</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={page} onChange={handleChangePage}>
                        <Tab label="Dados" />
                        <Tab label="Streams" />
                    </Tabs>
                </Box>
                <ChannelDataTabPanel 
                    onClose={handleClose} 
                    value={page} 
                    channel={channel} 
                    index={0} 
                />
                <StreamTabPanel
                    onClose={handleClose} 
                    value={page} 
                    channel={channel} 
                    index={1} 
                />
            </DialogContent>
        </Dialog>
    )
}

export default ChannelModal;
