import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import PropTypes from "prop-types";

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";

function ScreenFormModal({ open, handleClose, screen = null }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [branches, setBranches] = useState([]);
    const [title, setTitle] = useState(screen != null
        ? screen.name
        : 'Nova Tela'
    );

    const initialValues = {
        name: '', branch: ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (screen != null) {
                const uri = '/proxy/admin/screens/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/screens';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        branch: yup.string().required(),
        name: yup.string().required(),
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues: screen ?? initialValues,
        onSubmit: saveChanges,
        validationSchema
    });

    useEffect(() => {
        if (screen != null) setTitle(values.name);
    }, [screen, values.name])

    const handleGetBranches = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/branches";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais pacotes disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let branchesAux = response.data.result.branches.map(branch => ({
            label: branch.name,
            id: branch.id
        }));
        setBranches(branchesAux);
        setLoading(false);
    }

    useEffect(() => { if (open) handleGetBranches() }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {!loading &&
                    <DefaultAutoCompleteField
                        label="Branch"
                        value={values.branch}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('branch', value)
                        }}
                        options={branches}
                        error={touched.branch && Boolean(errors.branch)}
                        helperText={touched.branch && errors.branch}
                    />
                }
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <DefaultTextField
                            label="Nome"
                            value={values.name}
                            name='name'
                            onChange={handleChange}
                            required
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

ScreenFormModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default ScreenFormModal;
