import React from "react";
import InputMask from 'react-input-mask';
import { TextField } from "@mui/material"

function DefaultTextField(props) {
    const { mask, ...other } = props;

    return mask != null
        ? <InputMask mask={mask} {...other}>
            {(inputProps) => <TextField
                margin="dense"
                fullWidth
                variant="standard"
                {...inputProps}
            />}
        </InputMask>
        : <TextField
            margin="dense"
            fullWidth
            variant="standard"
            {...other}
        />
}

export default DefaultTextField;
