import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import * as yup from 'yup';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Box
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DefaultCheckField from "components/DefaultCheckField";

moment.updateLocale('en', { invalidDate: 'Não definido' });

function BranchFormModal({ open, handleClose, branch = null }) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(branch != null
        ? branch.name 
        : 'Nova Branch'
    );
   
    const formattedBranch = () => ({ 
        name: branch.name, 
        vod: branch.vod,
        catchup: branch.catchup,
        oauthEndpoint: branch.oauth.endpoint, 
        clientId: branch.oauth.clientId, 
        clientSecret: branch.oauth.clientSecret, 
        profiles: branch.profiles, 
        paymentUrl: branch.payment.url, 
        paymentId: branch.payment.id, 
        paymentHash: branch.payment.hash,
        paymentConfirmHash: branch.payment.confirmHash
    })

    const initialState = {
        name: '', vod: false, catchup: false, oauthEndpoint: '', clientId: '', 
        clientSecret: '', profiles: '', paymentUrl: '', paymentId: '', 
        paymentHash: '', paymentConfirmHash: ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();

        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (branch != null) {
                const uri = '/proxy/admin/branches/edit';
                formData.append('id', branch.id)
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/branches';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        profiles: yup.string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit, 
    } = useFormik({
        initialValues: branch ? formattedBranch() : initialState,
        onSubmit: saveChanges,
        validationSchema,
    });

    useEffect(() => {
        if (branch != null) {
            setTitle(values.name);
        }
    }, [branch, values.name])

    const oauthSection = (
        <>
            <Typography>Oauth</Typography>
            <DefaultTextField
                label="Endpoint"
                value={values.oauthEndpoint}
                name='oauthEndpoint'
                onChange={handleChange}
                error={touched.oauthEndpoint && Boolean(errors.oauthEndpoint)}
                helperText={touched.oauthEndpoint && errors.oauthEndpoint}
            />
            <DefaultTextField
                label="Client Id"
                value={values.clientId}
                name='clientId'
                onChange={handleChange}
                error={touched.clientId && Boolean(errors.clientId)}
                helperText={touched.clientId && errors.clientId}
                required={values.oauthEndpoint != ''}
            />
            <DefaultTextField
                label="Client Secret"
                value={values.clientSecret}
                name='clientSecret'
                onChange={handleChange}
                error={touched.clientSecret && Boolean(errors.clientSecret)}
                helperText={touched.clientSecret && errors.clientSecret}
                required={values.oauthEndpoint != ''}
            />
        </>
    )

    const paymentSection = (
        <>
            <Typography>Pagamento</Typography>
            <DefaultTextField
                label="Url"
                value={values.paymentUrl}
                name='paymentUrl'
                onChange={handleChange}
                error={touched.paymentUrl && Boolean(errors.paymentUrl)}
                helperText={touched.paymentUrl && errors.paymentUrl}
            />
            <DefaultTextField
                label="ID"
                value={values.paymentId}
                name='paymentId'
                onChange={handleChange}
                error={touched.paymentId && Boolean(errors.paymentId)}
                helperText={touched.paymentId && errors.paymentId}
            />
            <DefaultTextField
                label="Hash"
                value={values.paymentHash}
                name='paymentHash'
                onChange={handleChange}
                error={touched.paymentHash && Boolean(errors.paymentHash)}
                helperText={touched.paymentHash && errors.paymentHash}
            />
            <DefaultTextField
                label="Confirm Hash"
                value={values.paymentConfirmHash}
                name='paymentConfirmHash'
                onChange={handleChange}
                error={touched.paymentConfirmHash && Boolean(errors.paymentConfirmHash)}
                helperText={touched.paymentConfirmHash && errors.paymentConfirmHash}
            />
        </>
    );
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DefaultTextField
                    label="Nome"
                    value={values.name}
                    name='name'
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    required
                />
                <DefaultTextField
                    label="Máximo de perfis"
                    value={values.profiles}
                    name='profiles'
                    type='number'
                    onChange={handleChange}
                    error={touched.profiles && Boolean(errors.profiles)}
                    helperText={touched.profiles && errors.profiles}
                    required
                />
                <DefaultCheckField
                    label="VOD"
                    checked={values.vod}
                    name='vod'
                    onChange={handleChange}
                />
                <DefaultCheckField
                    label="Catchup"
                    checked={values.catchup}
                    name='catchup'
                    onChange={handleChange}
                />
                <Box mt={3}>{oauthSection}</Box>
                <Box mt={3}>{paymentSection}</Box>
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

BranchFormModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default BranchFormModal;
