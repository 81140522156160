import { Typography } from "@mui/material";

function TitleValue({title, value}) {
    return (
        <Typography variant="body2" component='span'>
            <b>{title}:</b> {value} <br />
        </Typography>
    )
}

export default TitleValue;