import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import * as yup from 'yup';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Box, Avatar,
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { imageUrl } from "utils";
import AvatarModal from "components/AvatarModal";
import DefaultSelectField from "components/DefaultSelectField";
import DefaultCheckField from "components/DefaultCheckField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

function ProfileFormModal({ open, handleClose, userId, profile = null }) {
    const navigate = useNavigate();

    const [selectAvatar, setSelectAvatar] = useState(false);
    const [title, setTitle] = useState(profile != null
        ? profile.name + ' ' + (profile.lastName ?? '')
        : 'Novo Perfil'
    );

    const initialValues = {
        default: false, avatarid: '', kidProfile: false,
        language: window.stxConfig.defaultLanguageId, maximumMoralityLevel: 0, name: '',
        settings: '', parentalControl: false
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }
        formData.append('userId', userId);

        try {
            if (profile != null) {
                const uri = '/crm/admin/profile/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/crm/admin/profile';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        parentalControl: yup.bool(),
        maximumMoralityLevel: yup
            .number()
            .when('parentalControl', {
                is: parentalControl => parentalControl,
                then: yup
                    .number()
                    .max(21, 'Idade máxima não pode ser maior que 21')
                    .min(1, 'Idade máxima não pode ser menor que 1')
            })
    });

    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleChange,
        handleSubmit
    } = useFormik({
        initialValues: profile != null
            ? {
                ...profile,
                parentalControl: profile.maximumMoralityLevel > 0
            }
            : initialValues,
        onSubmit: saveChanges,
        validationSchema
    });

    useEffect(() => {
        if (profile != null) {
            setTitle(values.name);
        }
    }, [profile, values.name])

    useEffect(() => {
        setFieldValue(
            'maximumMoralityLevel',
            values.parentalControl ? 1 : 0
        )
    }, [values.parentalControl])

    const handleOpenAvatar = () => setSelectAvatar(true);
    const handleSelectAvatar = (avatarId) => {
        setFieldValue('avatarid', avatarId);
        setSelectAvatar(false);
    }
    const handleCloseAvatar = () => setSelectAvatar(false);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <AvatarModal
                    open={selectAvatar}
                    handleClose={handleCloseAvatar}
                    onSelect={handleSelectAvatar}
                />
                <Box display='flex' justifyContent='center'>
                    <Avatar
                        onClick={handleOpenAvatar}
                        src={imageUrl(
                            window.stxConfig.imageTypes.avatar,
                            values.avatarid,
                            150,
                            150
                        )}
                    />
                </Box>
                <DefaultTextField
                    label="Nome"
                    value={values.name}
                    name='name'
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    required
                />
                <DefaultCheckField
                    label="Perfil kids?"
                    required
                    name="kidProfile"
                    checked={values.kidProfile}
                    onChange={handleChange}
                />
                {!values.default &&
                    <>
                        <DefaultCheckField
                            label="Controle Parental?"
                            required
                            name="parentalControl"
                            checked={values.parentalControl}
                            onChange={handleChange}
                        />
                        {values.parentalControl &&
                            <DefaultTextField
                                label="Idade máxima"
                                value={values.maximumMoralityLevel}
                                name='maximumMoralityLevel'
                                onChange={handleChange}
                                error={touched.maximumMoralityLevel && Boolean(errors.maximumMoralityLevel)}
                                helperText={touched.maximumMoralityLevel && errors.maximumMoralityLevel}
                                type="number"
                            />
                        }
                    </>
                }
                <DefaultSelectField
                    value={values.language}
                    label="Idioma preferido"
                    name="language"
                    onChange={handleChange}
                    items={window.stxConfig.languages}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

ProfileFormModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default ProfileFormModal;
