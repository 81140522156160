import React, { useState } from "react";

import { TableCell, TableRow } from "@mui/material";
import ScreenModal from "../ScreenModal";

function ScreenTile({ screen, updatedCallback }) {
    const [open, setOpen] = useState(false);

    const { id, name, branchName } = screen;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    };

    return (
        <>
            <ScreenModal screen={screen} open={open} handleClose={handleClose} />
            <TableRow onClick={handleOpen} style={{ cursor: 'pointer' }}>
                <TableCell scope="row">{id}</TableCell>
                <TableCell scope="row">{name}</TableCell>
                <TableCell scope="row">{branchName}</TableCell>
            </TableRow>
        </>
    )
}

export default ScreenTile;
