import React, { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { TableCell }from "@mui/material";
import TableRow from "@mui/material/TableRow";

import { getAxios } from "utils";

import { BarLoader } from "react-spinners";
import Feedback from "./Feedback";
import { useNavigate } from "react-router-dom";

function Feedbacks() {
    const navigate = useNavigate();
    
    const [relatorios, setRelatorios] = useState({});
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoaging] = useState(true);

    const handleGetRelatorios = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/feedbacks";
        let response;

        try {
            response = await axiosInstance.get(uri, {  params: { limit: 5 }  });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                console.error('Invalid Auth');
                return;
            }

            console.error(err);
            setErro("Erro interno");
            return;
        }
        
        if(!response.data) {
            setErro("Erro interno");
            return;
        }
        
        setErro(undefined);
        setLoaging(false);
        setRelatorios(response.data.result);
    }

    useEffect(async() => {
        return await handleGetRelatorios();
    }, [])

    return (
            <MDBox mt={8}>
                <MDBox mb={3}>
                    {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Feedbacks
                                    </MDTypography>
                                </MDBox>
                                <MDBox pt={3}>
                                    <TableContainer>
                                        <Table>
                                            <MDBox component="thead">
                                                <TableRow>
                                                    <TableCell><strong>Dispositivo</strong></TableCell>
                                                    <TableCell><strong>Feedback</strong></TableCell>
                                                </TableRow>
                                            </MDBox>
                                            {isLoading &&
                                                <BarLoader
                                                    color="#ACC0F0" 
                                                    cssOverride={{ width: "100%" }}
                                                />
                                            }
                                            <TableBody>
                                            {Object.keys(relatorios).map((relatorioIndice) => {
                                                if(relatorioIndice === "newAuthToken") {
                                                    return;
                                                }
                                        
                                                return <TableRow key={relatorioIndice}><Feedback relatorio={relatorios[relatorioIndice]}/></TableRow>
                                            })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        
    )
}

export default Feedbacks;