import { useSortable } from "@dnd-kit/sortable";
import { Card, CardActions, CardContent, CardHeader, Icon, Typography } from "@mui/material";
import { CSS } from "@dnd-kit/utilities";
import Asset from "./Asset";
import ItemMenu from "./ItemMenu";
import Category from "./Category";
import GenericItem from "./GenericItem";

function ScreenItem({ item, updateCallBack, designMode }) {
    const designModeValue = window
        .stxConfig
        .screenContainerDesigns
        .find(design => design.value == designMode);

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
    } = useSortable({
        id: item.id,
        data: { type: "Item", item },
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };

    let createItem = () => {
        switch (item.type) {
            case 1:
                return <Asset
                    id={item.value.asset} 
                    designMode={designModeValue}
                />
            case 2:
                return <Category
                    id={item.value.category}
                    designMode={designModeValue}
                />
            default:
                return <GenericItem
                    designMode={designModeValue}
                />;
        }
    }

    const cardStyle = {
        width: designModeValue?.width,
        padding: 0,
        border: 1
    }

    const cardContentStyle = {
        width: designModeValue?.width,
        height: designModeValue?.height,
        padding: 0
    }

    return (
        <div style={style} ref={setNodeRef}>
            <Card sx={cardStyle}>
                <CardHeader
                    title={
                        <div {...listeners} {...attributes}>
                            <Icon>drag_indicator</Icon>
                        </div>
                    }
                    action={<ItemMenu 
                        updateCallBack={updateCallBack}
                        item={item}
                        parent={item.id}
                    />}
                />
                <CardContent sx={cardContentStyle}>
                    {designModeValue && createItem()}
                </CardContent>
                <CardActions>
                    <Typography variant="h4" component='h2'>
                        {item.name}
                    </Typography>
                </CardActions>
            </Card>
        </div>
    )
}

export default ScreenItem;