import React, { useEffect, useState } from "react";

import {
    Dialog, DialogContent, DialogTitle, IconButton, Icon, Box, Table, TableBody, TableRow, TableCell, TablePagination
} from "@mui/material";
import ChannelTile from "../ChannelTile";
import { getAxios } from "utils";
import ServicePlanFormModal from "../ServicePlanFormModal";
import MDBox from "components/MDBox";
import SearchBar from "components/SearchBar";
import DefaultCheckField from "components/DefaultCheckField";
import TablePaginationActions from "components/TablePaginationActions";

const maxChannels = 5;

function ServicePlanModal({ open, handleClose, servicePlan }) {
    const [channels, setChannels] = useState([]);
    const [update, setUpdate] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [edit, setEdit] = useState(false);
    const [totalChannels, setTotalChannels] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [search, setSearch] = useState('');
    const [allSelected, setAllSelected] = useState(false);
    const { name } = servicePlan;

    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleSearch = (value) => {
        setSearch(value);
        setPageAssets(0);
    }

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        handleClose();
    }

    const handleEditChannel = async (channelId, value) => {
        const axiosInstance = getAxios();
        const uri = '/proxy/admin/channels';
        
        try {
            if (value) {
                const formData = new FormData();
                formData.append('servicePlanId', servicePlan.id);
                formData.append('channelId', channelId);
                await axiosInstance.post(uri, formData);
            } 
            else {
                const params = {
                    servicePlanId: servicePlan.id,
                    id: channelId
                }
                await axiosInstance.delete(uri, { params });
            }
            await handleGetChannels();
        } catch (err) {
            console.log(err);
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
            }
            setError('Erro interno');
        }
    }
    
    const handleEditAllChannel = async (value) => {
        const axiosInstance = getAxios();
        const uri = '/proxy/admin/servicePlans/all';
        
        try {
            if (allSelected) {
                const params = {
                    servicePlanId: servicePlan.id,
                    search
                }
                await axiosInstance.delete(uri, { params });
            } 
            else {
                const formData = new FormData();
                formData.append('servicePlanId', servicePlan.id);
                formData.append('search', search);
                await axiosInstance.post(uri, formData);
            }
            await handleGetChannels();
        } catch (err) {
            console.log(err);
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
            }
            setError('Erro interno');
        }
    }

    const handleGetChannels = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/channels";
        let response;
        const params = {
            servicePlanId: servicePlan.id,
            search,
            limitStart: pageAssets * maxChannels,
            limit: maxChannels
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            if (err.response.data.error.code == 404) {
                setChannels([]);
                setTotalChannels(0);
                return;
            }

            setError("Erro interno");
            setLoading(false);
            return;
        }

        if (!response.data.result) {
            setError("Erro interno");
            setLoading(false);
            return;
        }

        setError(undefined);
        setLoading(false);
        setChannels(response.data.result.channels);
        setTotalChannels(response.data.result.total);
        setAllSelected(response.data.result.allSelected);
    }

    useEffect(() => {
        handleGetChannels();
        setUpdate(false);
    }, [servicePlan, update, pageAssets, search])
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            scroll="paper"
        >
            <DialogTitle display='flex' justifyContent='space-between'>
                {name}
                <IconButton onClick={handleEdit}>
                    <Icon>edit</Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ServicePlanFormModal
                    open={edit}
                    handleClose={handleStopEdit}
                    servicePlan={servicePlan}
                />
                <Box mt={1} display="flex" justifyContent="space-between" gap={5}>
                    <SearchBar label="Pesquisar" onSearch={handleSearch} />
                </Box>
                <Table>
                    <MDBox component="thead">
                        <TableRow>
                            <TableCell width="5">
                                <DefaultCheckField
                                    checked={allSelected}
                                    onChange={handleEditAllChannel}
                                />
                            </TableCell>
                            <TableCell><strong>Nome</strong></TableCell>
                        </TableRow>
                    </MDBox>
                    <TableBody>
                        {channels.map((channel, index) =>
                            <ChannelTile
                                key={`channel_${index}`}
                                channel={channel}
                                onChange={handleEditChannel}
                            />
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[maxChannels]}
                    component="div"
                    count={totalChannels}
                    rowsPerPage={maxChannels}
                    page={pageAssets}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ServicePlanModal;
