import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";
import { useEffect, useState } from "react";
import { getAxios } from "utils";

function DefaultSelectFromData(props) {
    const { uri, open, parser, ...other } = props;
    
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const handleGetItems = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais pacotes disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let itemsAux = parser(response.data.result);
        setItems(itemsAux);
        setLoading(false);
    }
    
    useEffect(() => { if (open) handleGetItems() }, [open]);

    return !loading &&
        <DefaultAutoCompleteField
            options={items}
            {...other}
        />
}

export default DefaultSelectFromData;