import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import TitleValue from "components/TitleValue";
import parse from 'html-react-parser';
import moment from "moment";

function EmailHistoryModal({ open, handleClose, email }) {
    const { subject, body, receiver, date, senderName } = email
    const formattedDate = moment(date).format('DD/MM/YYYY HH:mm:ss')

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>{`${subject} <${receiver}>`}</DialogTitle>
            <DialogContent>
                <TitleValue title="Destinatário" value={receiver} />
                <TitleValue title="Remetente" value={senderName} />
                <TitleValue title="Assunto" value={subject} />
                <TitleValue title="Data" value={formattedDate} />
                <Typography variant="h4">Conteúdo</Typography>
                {parse(body)}
            </DialogContent>
        </Dialog>
    )
}

export default EmailHistoryModal;
