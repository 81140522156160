import SearchBar from "components/SearchBar";
import CategoryFilter from "components/CategoryFilter";
import { Box } from "@mui/material";
import DefaultCheckField from "components/DefaultCheckField";
import DefaultDateField from "components/DefaultDateField";

function Filters({ values, onChange }) {
    return (
        <>
            <Box pr={5} display="flex" justifyContent="space-between" gap={3}>
                <SearchBar
                    label="Pesquisar"
                    width={550}
                    onSearch={(value) => onChange('search', value)}
                />
                <CategoryFilter
                    label="Categoria"
                    value={values.categoryId}
                    onChange={(value) => onChange('categoryId', value)}
                />
                <CategoryFilter
                    label="Serie"
                    isSerie
                    value={values.serieId}
                    onChange={(value) => onChange('serieId', value)}
                />
                <Box width={500}>
                    <DefaultCheckField
                        label="É filme?"
                        required
                        disabled={values.isEpisode}
                        name="active"
                        checked={values.isMovie}
                        onChange={(value) => onChange('isMovie', value.target.checked)}
                    />
                </Box>
                <Box width={600}>
                    <DefaultCheckField
                        label="É episódio?"
                        required
                        disabled={values.isMovie}
                        name="active"
                        checked={values.isEpisode}
                        onChange={(value) => onChange('isEpisode', value.target.checked)}
                    />
                </Box>
            </Box>
            <Box px={3} display="flex" gap={5} >
                <DefaultDateField
                    value={values.start}
                    label="Início"
                    onChange={val => onChange('start', val)}
                />
                <DefaultDateField
                    value={values.end}
                    label="Fim"
                    onChange={val => onChange('end', val)}
                />
            </Box>
        </>
    )
}

export default Filters;