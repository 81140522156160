import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import DefaultCheckField from 'components/DefaultCheckField';

function ChannelTile ({channel, onChange}) {
    return (
        <TableRow>
            <TableCell scope='row'>
                <DefaultCheckField 
                    checked={channel.belongs != 0} 
                    onChange={(e) => onChange(channel.id, e.target.checked)} 
                />
            </TableCell>
            <TableCell scope='row'>{channel.name}</TableCell>
        </TableRow>
    )
}

export default ChannelTile;