import React, { useEffect, useState } from "react";
import * as yup from 'yup';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

function SenderFormModal({ open, handleClose, sender = null }) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(sender != null
        ? sender.name
        : 'Novo Sender'
    );

    const initialState = {
        name: '', host: '', port: '', user: '', pass: ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();

        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (sender != null) {
                const uri = '/proxy/admin/emailSenders/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/emailSenders';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        host: yup.string().required(),
        port: yup.number().required(),
        user: yup.string().required(),
        from: yup.string().required(),
        // pass: yup
        //     .string()
        //     .when([], {
        //         is: () => sender !== null,
        //         then: yup.string().required
        //     }),
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        resetForm,
    } = useFormik({
        initialValues: { ...sender, pass: '' } ?? initialState,
        onSubmit: saveChanges,
        validationSchema,
    });

    useEffect(() => {
        if (sender != null) setTitle(values.name);
    }, [sender, values.name])

    useEffect(() => { if (open) resetForm(); }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DefaultTextField
                    required
                    label="Nome"
                    value={values.name}
                    name='name'
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
                <DefaultTextField
                    required
                    label="Host"
                    value={values.host}
                    name='host'
                    onChange={handleChange}
                    error={touched.host && Boolean(errors.host)}
                    helperText={touched.host && errors.host}
                />
                <DefaultTextField
                    required
                    label="Porta"
                    value={values.port}
                    name='port'
                    type='number'
                    onChange={handleChange}
                    error={touched.port && Boolean(errors.port)}
                    helperText={touched.port && errors.port}
                />
                <DefaultTextField
                    required
                    label="Usuário"
                    value={values.user}
                    name='user'
                    onChange={handleChange}
                    error={touched.user && Boolean(errors.user)}
                    helperText={touched.user && errors.user}
                />
                <DefaultTextField
                    required
                    label={ sender ? "Mudar senha" :  "Senha" }
                    value={values.pass}
                    name='pass'
                    type='password'
                    onChange={handleChange}
                    error={touched.pass && Boolean(errors.pass)}
                    helperText={touched.pass && errors.pass}
                />
                <DefaultTextField
                    required
                    label="Remetente"
                    value={values.from}
                    name='from'
                    onChange={handleChange}
                    error={touched.from && Boolean(errors.from)}
                    helperText={touched.from && errors.from}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SenderFormModal;
