import { FormControl, Icon, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";

function SearchBar({ label, onSearch, width = 200 }) {
    return (
        <FormControl sx={{ ml: 2, width }} variant="outlined">
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                onInput={(e) => onSearch(e.target.value)}
                label={label}
                variant="outlined"
                size="small"
                startAdornment={
                    <InputAdornment position="start">
                        <Icon>search</Icon>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}

export default SearchBar;