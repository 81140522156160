import { Box } from "@mui/material";
import GenericItem from "../ScreenItem/GenericItem";

function History({ container }) {
    const gap = 10;

    const designModeValue = window
        .stxConfig
        .screenContainerDesigns
        .find(design => design.value == container.designMode);

    return (
        <Box display='flex' overflow='auto' gap={`${gap}px`}>
            {[...Array(20).keys()].map((index) => 
                <GenericItem
                    key={`item_${index}`}
                    designMode={designModeValue}
                />
            )}
        </Box>
    );
}

export default History;