import React, { useState } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Alert
} from "@mui/material";

import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";
import DefaultSelectField from "components/DefaultSelectField";
import DefaultTextField from "components/DefaultTextField";

function StreamFormModal({ open, handleClose, channelId, stream }) {
    const navigate = useNavigate();

    const [error, setError] = useState(undefined);

    const initialState = { technology: '', url: '' };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }
        formData.append('channelId', channelId);

        try {
            const uri = '/proxy/admin/channelStreams';
            await axiosInstance.post(uri, formData);
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        technology: yup.string().required(),
        url: yup.string().required(),
    });

    const {
        values,
        touched,
        errors,
        handleSubmit,
        handleChange
    } = useFormik({
        initialValues: stream ?? initialState,
        validationSchema,
        async onSubmit(values, { resetForm }) {
            if (await saveChanges(values)) {
                handleClose();
                resetForm();
            }
        }
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Nova Stream</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }
                <DefaultSelectField
                    required
                    value={values.technology}
                    label='Tecnologia'
                    name='technology'
                    onChange={handleChange}
                    items={window.stxConfig.streamTechnologies}
                    error={touched.technology && Boolean(errors.technology)}
                    helperText={touched.technology && errors.technology}
                />
                <DefaultTextField
                    required
                    label="Url"
                    value={values.url}
                    name="url"
                    onChange={handleChange}
                    error={touched.url && Boolean(errors.url)}
                    helperText={touched.url && errors.url}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default StreamFormModal;