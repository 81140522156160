import { useEffect, useMemo, useState } from "react";
import {
    DndContext, DragOverlay, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams } from "react-router-dom";
import { getAxios } from "utils";
import ScreenContainer from './components/ScreenContainer';
import { Box, Button, Icon } from '@mui/material';
import ContainerFormModal from './components/ScreenContainer/ContainerFormModal';
import ScreenItem from "./components/ScreenItem";
import container from "assets/theme/components/container";

function Screen() {
    const { id: screenId } = useParams();
    const [screen, setScreen] = useState([]);
    const [containers, setContainers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [add, setAdd] = useState(false);
    const [error, setError] = useState(undefined);
    const [activeContainer, setActiveContainer] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const [update, setUpdate] = useState(false);
    const [showButton, setShowButton] = useState(null);
    const [order, setOrder] = useState(null);

    const gap = 10;

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    function onDragStart(event) {
        if (event.active.data.current?.type === "Container") {
            setActiveContainer(event.active.data.current.container);
            return;
        }

        if (event.active.data.current?.type === "Item") {
            setActiveItem(event.active.data.current.item);
            return;
        }
    }

    function onDragEnd(event) {
        setActiveContainer(null);
        setActiveItem(null);

        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overId = over.id;

        const isActiveAContainer = active.data.current?.type === "Container";
        if (isActiveAContainer) {
            setContainers((containers) => {
                const activeContainerIndex = containers.findIndex((col) => col.id === activeId);
                const overContainerIndex = containers.findIndex((col) => col.id === overId);
                const newContainers = arrayMove(containers, activeContainerIndex, overContainerIndex);

                handleUpdateContentAnimation(newContainers);
                return newContainers;
            })
        } else {
            const activeContainer = active.data.current.item.container;
            const overContainer = over.data.current.item.container;

            if (activeContainer != overContainer) return;

            setContainers((prevContainers) => {
                const containerIndex = prevContainers.findIndex(container => container.id == activeContainer);

                const newContainers = [...prevContainers];
                const container = { ...newContainers[containerIndex] };

                const activeItemIndex = container.items.findIndex((col) => col.id === activeId);
                const overItemIndex = container.items.findIndex((col) => col.id === overId);

                let newItems = [...container.items];
                newItems = arrayMove(container.items, activeItemIndex, overItemIndex);
                container.items = newItems;
                newContainers[containerIndex] = container;

                handleUpdateContentAnimation(newContainers);
                return newContainers;
            });
        }
    }

    const handleGetContentAnimation = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/contentAnimation";
        let response;
        const params = { screenId }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data.result) {
            setError("Erro interno");
            return;
        }

        const containersAux = response.data.result.screen.containers;
        setScreen(response.data.result.screen);
        setContainers(containersAux);
        setLoading(false);
        setError(undefined);
    }

    const handleUpdateContentAnimation = async (items) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        formData.append('items', JSON.stringify(items));
        formData.append('screenId', screenId);

        try {
            const uri = '/proxy/admin/contentAnimation';
            await axiosInstance.post(uri, formData);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    useEffect(async () => {
        await handleGetContentAnimation()
        setUpdate(false)
    }, [update]);

    const containersId = useMemo(
        () => containers.map(item => item?.id),
        [containers]
    );

    const handleAdd = (index) => {
        setAdd(true);
        setOrder(index + 1);
    }
    const stopAdd = () => {
        setAdd(false);
        setUpdate(true);
    }

    const handleMouseEnter = (index) => setShowButton(index);
    const handleMouseLeave = () => setShowButton(null);

    const listStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: `${gap}px`
    }

    let createAddButton = (index) => <Box
        style={{
            height: showButton == index ? null : 10,
            display: 'flex',
            flexDirection: 'column',
        }}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
    >
        {showButton == index &&
            <Button
                startIcon={<Icon>add</Icon>}
                onClick={() => handleAdd(index)}
                style={{ marginTop: gap }}
            >Adicionar</Button>
        }
    </Box>


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <h1>{screen.name}</h1>
            <ContainerFormModal
                open={add}
                handleClose={stopAdd}
                screenId={screenId}
                order={order}
            />
            {!loading &&
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                >
                    <SortableContext items={containersId} strategy={rectSortingStrategy}>
                        <Box sx={listStyle}>
                            {containers.map((container, index) =>
                                <Box key={`container_${index}`}>
                                    <ScreenContainer
                                        container={container}
                                        updateCallBack={setUpdate}
                                    />
                                    {index < containers.length - 1
                                        && createAddButton(index)
                                    }
                                </Box>
                            )}
                            <Button
                                startIcon={<Icon>add</Icon>}
                                onClick={() => handleAdd(containers.length - 1)}
                                style={{ marginTop: gap }}
                            >Adicionar</Button>
                        </Box>
                    </SortableContext>
                    <DragOverlay>
                        {activeContainer && (
                            <ScreenContainer
                                container={activeContainer}
                                updateCallBack={setUpdate}
                            />
                        )}
                        {activeItem && (
                            <ScreenItem
                                item={activeItem}
                                updateCallBack={setUpdate}
                                designMode={container.designMode}
                            />
                        )}
                    </DragOverlay>
                </DndContext>
            }
        </DashboardLayout>
    )
}

export default Screen;