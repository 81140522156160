import React, { useState } from 'react';

import { TableCell, TableRow } from "@mui/material";
import ServicePlanModal from '../ServicePlanModal';

function ServicePlanTile({ servicePlan, updatedCallback }) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    };

    return (
        <>
            <ServicePlanModal open={open} handleClose={handleClose} servicePlan={servicePlan} />
            <TableRow onClick={handleOpen} style={{ cursor: 'pointer' }}>
                <TableCell scope="row">{servicePlan.id}</TableCell>
                <TableCell scope="row">{servicePlan.name}</TableCell>
            </TableRow>
        </>
    )
}

export default ServicePlanTile;