import React, { useEffect, useState } from 'react';

import { TableCell }from "@mui/material";

import { Relatorios } from '../../../utils';

function Feedback({ relatorio }) {
    const [bodyFeedback, setBodyFeedback] = useState("");
    const [deviceFeedback, setDeviceFeedback] = useState(0);

    const handleTratamentoDados = () => {
        const { body, device } = relatorio;

        setBodyFeedback(body);
        setDeviceFeedback(device);
    }

    useEffect(() => {
        return handleTratamentoDados();
    }, [])

    return (
        <>
            <TableCell>{Relatorios.devices[deviceFeedback]}</TableCell>
            <TableCell>{bodyFeedback}</TableCell>
        </>
    )
}

export default Feedback;