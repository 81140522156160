import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import {
    Dialog, DialogContent, DialogTitle, Tabs, Tab, Box
} from "@mui/material";
import PlanDataTabPanel from "../PlanDataTabPanel";
import PackagesTabPanel from "../PackagesTabPanel";
import ChannelsTabPanel from "../ChannelsTabPanel";

function PlanModal({ open, handleClose, plan }) {
    const [page, setPage] = useState(0);
    const { name } = plan;

    const handleChangePage = (_, newPage) => setPage(newPage);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            scroll="paper"
        >
            <DialogTitle>{name}</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={page} onChange={handleChangePage}>
                        <Tab label="Dados" />
                        <Tab label="Pacotes VOD" />
                        <Tab label="Canais" />
                    </Tabs>
                </Box>
                <PlanDataTabPanel onClose={handleClose} value={page} plan={plan} index={0} />
                <PackagesTabPanel onClose={handleClose} value={page} plan={plan} index={1} />
                <ChannelsTabPanel onClose={handleClose} value={page} plan={plan} index={2} />
            </DialogContent>
        </Dialog>
    )
}

PlanModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default PlanModal;
