import React from 'react';

import { TableCell, TableRow } from "@mui/material";

function WatchedTile({ history }) {
    return (
        <TableRow>
            <TableCell scope="row">{history.categoryName}</TableCell>
            <TableCell scope="row">{history.serieName ?? '-'}</TableCell>
            <TableCell scope="row">{history.name}</TableCell>
            <TableCell scope="row">{history.total}</TableCell>
        </TableRow>
    )
}

export default WatchedTile;