import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import RelatorioUsuario from "./RelatorioUsuario";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableHead } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { TableCell }from "@mui/material";
import TableRow from "@mui/material/TableRow";

import { getAxios, getAccessToken, Relatorios } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';

function RelatoriosUsusarios() {
    const [relatorios, setRelatorios] = useState({});
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);

    const navigate = useNavigate();

    const handleGetRelatorio = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/relatorioLogin";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setRelatorios(response.data.result);
    }

    useEffect(async() => {
        return await handleGetRelatorio()
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={8}>
                <MDBox mb={3}>
                    {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Relatório de usuários
                                    </MDTypography>
                                </MDBox>
                                <MDBox pt={3}>
                                    <TableContainer>
                                    <Table>
                                        <MDBox component="thead">
                                            <TableRow>
                                                <TableCell><strong>Email</strong></TableCell>
                                                <TableCell><strong>Data</strong></TableCell>
                                                <TableCell><strong>Tempo Logado</strong></TableCell>
                                                <TableCell><strong>Ação</strong></TableCell>
                                                <TableCell><strong>Operadora</strong></TableCell>
                                            </TableRow>
                                        </MDBox>
                                        {isLoading &&
                                            <BarLoader
                                                color="#ACC0F0" 
                                                cssOverride={{ width: "100%" }}
                                            />
                                        }
                                        <TableBody>
                                        {Object.keys(relatorios).map((relatorioIndice) => {
                                            if(relatorioIndice === "newAuthToken") {
                                                return;
                                            }
                                        
                                            return <TableRow><RelatorioUsuario relatorio={relatorios[relatorioIndice]}/></TableRow>
                                        })}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    )
}

export default RelatoriosUsusarios;
