import { useMemo, useState } from "react";
import { SortableContext } from "@dnd-kit/sortable";
import { Box, Button, Icon } from "@mui/material";
import ScreenItem from "../ScreenItem";
import ItemFormModal from "../ScreenItem/ItemFormModal";

function Container({ container, updateCallBack, }) {
    const [add, setAdd] = useState(false);
    const [showButton, setShowButton] = useState(null);
    const [order, setOrder] = useState(null);

    const gap = 10;

    const handleMouseEnter = (index) => setShowButton(index);
    const handleMouseLeave = () => setShowButton(null);

    const itemsIds = useMemo(
        () => container.items.map((item) => item.id),
        [container.items]
    );

    const handleAdd = (index) => {
        setOrder(index + 1);
        setAdd(true);
    }
    const stopAdd = () => {
        setAdd(false);
        updateCallBack(true);
        setOrder(null);
    }

    const listStyle = {
        display: 'flex',
        overflow: 'auto',
        gap: `${gap}px`,
        paddingRight: 10
    }

    let createAddButton = (index) => <Box
        style={{
            width: showButton == index ? null : 10,
            height: '100%'
        }}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
    >
        {showButton == index &&
            <Button
                style={{
                    height: '100%',
                    marginLeft: gap
                }}
                onClick={() => handleAdd(index)}
            >
                <Icon>add</Icon>
            </Button>
        }
    </Box>

    return (
        <>
            <ItemFormModal
                open={add}
                handleClose={stopAdd}
                container={container.id}
                order={order}
            />
            <Box sx={listStyle} >
                <SortableContext items={itemsIds}>
                    {container.items.map((item, index) => (
                        <Box key={`item_${item.id}`} display='flex'>
                            <ScreenItem
                                item={item}
                                updateCallBack={updateCallBack}
                                designMode={container.designMode}
                            />
                            {createAddButton(index)}
                        </Box>
                    ))}
                </SortableContext>
                {container.items.length == 0 && <Button
                    startIcon={<Icon>add</Icon>}
                    onClick={() => handleAdd(container.items.length - 1)}
                >Adicionar</Button>}
            </Box>
        </>
    )
}

export default Container;