import React, { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import {
    Card, Table, TableCell, TablePagination, TableBody, TableContainer
} from "@mui/material";
import TableRow from "@mui/material/TableRow";

import { getAxios } from "../../utils";

import { BarLoader } from "react-spinners";
import { createSearchParams, useNavigate } from "react-router-dom";
import TablePaginationActions from "components/TablePaginationActions";
import WatchedTile from "./components/WatchedTile";
import Filters from "./components/Filters";
import { useFormik } from "formik";

const maxPlans = 10;

function WatchedReport() {
    const [history, setHistory] = useState([]);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [totalHistory, setTotalHistory] = useState(1);

    const navigate = useNavigate();

    const handleChangePage = (_, newpage) => setPageAssets(newpage);

    const initialValues = {
        search: '', serieId: null, categoryId: null, isMovie: false,
        start: '', end: ''
    }

    const { values, setFieldValue } = useFormik({ initialValues });

    const handleChangeFilter = (field, value) => {
        setFieldValue(field, value);
        setPageAssets(0);
    }

    const handleGetHistory = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/userProfileHistory";
        let response;
        const params = {
            limitStart: pageAssets * maxPlans,
            limit: maxPlans,
            search: values.search,
            category: values.categoryId,
            serie: values.serieId,
            isMovie: values.isMovie,
            isEpisode: values.isEpisode,
            start: values.start,
            end: values.end,
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            if (err.response.data.error.code == 404) {
                setHistory([]);
                setTotalHistory(0);
                return;
            }

            setErro("Erro interno");
            return;
        }

        if (!response.data) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setHistory(response.data.result.history);
        setTotalHistory(response.data.result.total)
    }

    useEffect(async () => {
        await handleGetHistory();
    }, [pageAssets, values])

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Conteúdos Assistidos
        </MDTypography>
    </MDBox>;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <Filters values={values} onChange={handleChangeFilter} />
                        <TableContainer>
                            <Table>
                                <MDBox component="thead">
                                    <TableRow>
                                        <TableCell><strong>Categoria</strong></TableCell>
                                        <TableCell><strong>Série</strong></TableCell>
                                        <TableCell><strong>Asset</strong></TableCell>
                                        <TableCell><strong>Assistido</strong></TableCell>
                                    </TableRow>
                                </MDBox>
                                <TableBody>
                                    {history.map((item, index) =>
                                        <WatchedTile
                                            key={`history_${index}`}
                                            history={item}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                            {isLoading &&
                                <BarLoader
                                    color="#ACC0F0"
                                    cssOverride={{ width: "100%" }}
                                />
                            }
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[maxPlans]}
                            component="div"
                            count={totalHistory}
                            rowsPerPage={maxPlans}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default WatchedReport;