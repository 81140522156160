import { Box, Button, CircularProgress, Divider,List } from "@mui/material"
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { getAxios } from "utils";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import StreamTile from "./StreamTile";
import StreamFormModal from "./StreamFormModal";

function StreamTabPanel ({channel, value, index}) {
    const navigate = useNavigate();

    const [streams, setStreams] = useState([]);
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);

    const handleGetStreams = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/channelStreams";
        let response;
        const params = { channelId: channel.id }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setStreams(response.data.result.streams);
    }

    useEffect(() => { 
        handleGetStreams(); 
        setUpdate(false);
    }, [channel, update])

    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        setUpdate(true);
    }

    return (
        <div hidden={value !== index}>
            {value === index && (
                <MDBox p={ 3 }>
                    <StreamFormModal
                        channelId={channel.id} 
                        open={open} 
                        handleClose={handleClose} 
                    />
                    <Box
                        display='flex'
                        justifyContent='flex-end'
                    >
                        <Button 
                            variant="contained" 
                            style={{color: 'white'}}
                            onClick={handleOpen}
                            startIcon={<AddIcon />}
                        >
                            Adicionar
                        </Button>
                    </Box>
                    {loading 
                        ? <CircularProgress />
                        : <List>{streams.map((stream, index) => 
                            <React.Fragment key={`stream_${index}`}>
                                <StreamTile 
                                    stream={stream} 
                                    channelId={channel.id} 
                                    updateCallBack={setUpdate}
                                />
                                {index < streams.length - 1 && <Divider />}
                            </React.Fragment> 
                        )}</List>
                    }
                </MDBox>
            )}
        </div>
    )
}

export default StreamTabPanel