import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import { 
    Card, Table, TableBody, TableRow, TableCell, Button, TablePagination, Box, Icon 
} from "@mui/material";

import { getAxios } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';
import ChannelTile from "./components/ChannelTile";
import ChannelFormModal from "./components/ChannelFormModal";
import SearchBar from "components/SearchBar";
import TablePaginationActions from "components/TablePaginationActions";

const maxChannels = 10;

function Channels() {
    const [channels, setChannels] = useState([]);
    const [totalChannels, setTotalChannels] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    
    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleGetChannels = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/channels";
        let response;
        const params = {
            limitStart: pageAssets*maxChannels, 
            limit: maxChannels,
            search
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            console.log(err)

            if (err.response.status === 404) {
                setErro('');
                setChannels([]);
                setTotalChannels(0)
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setChannels(response.data.result.channels);
        setTotalChannels(response.data.result.total)
    }

    useEffect(async() => { 
        await handleGetChannels() 
        setUpdate(false)
    }, [update, pageAssets, search]);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        setUpdate(true);
    }
    
    const handleSearch = async (value) => {
        setSearch(value);
        setPageAssets(0);
    } 

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Canais
        </MDTypography>
    </MDBox>

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <ChannelFormModal open={open} handleClose={handleClose} />
            <Box
                display='flex'
                justifyContent='flex-end'
            >
                <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={handleOpen}
                    startIcon={<Icon>add</Icon>}
                >
                    Adicionar
                </Button>
            </Box>
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>Id</strong></TableCell>
                                    <TableCell><strong>Nome</strong></TableCell>
                                    <TableCell width="5"></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{channels.map((channel, index) =>
                                <ChannelTile
                                    key={`channel_${index}`}
                                    channel={channel}
                                    updatedCallback={setUpdate}
                                />
                            )}</TableBody>
                        </Table>
                        {isLoading &&
                            <BarLoader
                                color="#ACC0F0" 
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination 
                            rowsPerPageOptions={[maxChannels]}
                            component="div"
                            count={totalChannels}
                            rowsPerPage={maxChannels}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default Channels;
