import { Box, Icon } from "@mui/material";

function GenericItem({ designMode }) {
    return (
        <Box
            minWidth={designMode.width}
            height={designMode.height}
            border={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
        >
            <Icon>category</Icon>
        </Box>
    );
}

export default GenericItem;