import { Icon, IconButton, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { getAxios, sqlToDateTime } from 'utils';
import WarningModal from 'components/WarningModal';
import { useNavigate } from 'react-router-dom';
import TitleValue from 'components/TitleValue';
import UserPlanFormModal from '../UserPlanFormModal';

function UserPlanTile ({plan, userId, updateCallBack}) {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);

    const { contractEndDate, totalPrice } = plan;
    const validDate = sqlToDateTime(contractEndDate);
    const total = totalPrice.toLocaleString(
        'pt-br', {style: 'currency', currency: 'BRL'}
    );
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updateCallBack(true);
    }

    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/userOptions';
        const params = {
            userId,
            id: plan.id
        }

        try {
            await axiosInstance.delete(uri, { params });
            updateCallBack(true);
            handleCloseDeleteWarning();
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const handleOpenDeleteWarning = () => setDeleteWarning(true);
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <UserPlanFormModal 
                open={open} 
                handleClose={handleClose}
                userId={userId} 
                userPlan={plan} 
            />
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Plano'
                message={`Tem certeza que deseja bloquear o acesso ao plano ${plan.optionName}?`}
            />

            <ListItem>
                <ListItemText 
                    primary={plan.optionName}
                    secondary={
                        <>
                            <TitleValue title='Valido até' value={validDate} />
                            <TitleValue title='Valor total' value={total} />
                        </>
                    }
                />
                <IconButton edge='end' aria-label='edit' onClick={handleOpen}>
                    <Icon>edit</Icon>
                </IconButton>
                <IconButton edge='end' aria-label='edit' onClick={handleOpenDeleteWarning}>
                    <Icon>block</Icon>
                </IconButton>
            </ListItem>
        </>
    )
}

export default UserPlanTile;