import React, { useEffect } from "react";
import { unsetAccesToken } from "utils";
import { useNavigate } from "react-router-dom";

function Logout() {
    unsetAccesToken();

    const navigate = useNavigate();

    useEffect(() => {
        navigate("/authentication/sign-in")
    }, [])

    return(<>Saindo</>)
}

export default Logout;