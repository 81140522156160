import { useFormik } from 'formik';
import { useEffect, useState } from "react";
import * as yup from 'yup';

import {
    Alert,
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent, DialogTitle
} from "@mui/material";

import DefaultCheckField from "components/DefaultCheckField";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { getAxios } from "utils";
import ImageField from 'components/ImageField';
import { imageUrl } from 'utils';

function CategoryFormModal({ open, handleClose, category, catalogId = '', parentId = '' }) {
    const navigate = useNavigate();

    const [error, setError] = useState(undefined);
    const [title, setTitle] = useState(category != null
        ? category.name
        : 'Nova Categoria'
    );

    const initialValues = {
        id: category?.id ?? '',
        catalog: catalogId,
        externalId: category?.externalId ?? '', 
        parent: category?.parent ?? parentId, 
        adult: category?.adult ?? false,
        dynamic: category?.dynamic ?? false, 
        fictif: category?.fictif ?? false, 
        idView: category?.idView ?? '', 
        main: category?.main ?? false, 
        mLevel: category?.mLevel ?? 0,
        name: category?.name ?? '', 
        order: category?.order ?? 0, 
        season: category?.season ?? false, 
        serie: category?.serie ?? false, 
        synopsis: category?.synopsis ?? '',
        coverImage: [], 
        cover: '', 
        posterImage: [], 
        poster: ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (category != null) {
                const uri = '/proxy/admin/categories/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/categories';
                await axiosInstance.post(uri, formData);
            }
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        mLevel: yup
            .number()
            .required()
            .max(21, 'Idade máxima não pode ser maior que 21')
            .min(0, 'Idade máxima não pode ser menor que 0 (Livre)')
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues,
        validationSchema,
        async onSubmit(values, { resetForm }) {
            if (await saveChanges(values)) {
                handleClose();
                resetForm();
            }
        }
    });

    useEffect(() => {
        if (category != null) {
            setTitle(values.name);
        }
    }, [category, values.name])

    const onChangePoster = (image) => {
        setFieldValue('posterImage', image);
        setFieldValue('poster', image[0]?.file)
    }

    const onChangeCover = (image) => {
        setFieldValue('coverImage', image);
        setFieldValue('cover', image[0]?.file)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }
                <ImageField
                    label="Poster"
                    value={values.posterImage}
                    onChange={onChangePoster}
                    initialValue={category?.poster && imageUrl(
                        window.stxConfig.imageTypes.seriePoster,
                        category.id,
                        152,
                        230
                    )}
                    width={152}
                    height={230}
                />
                <Box mt={2}>
                    <ImageField
                        label="Cover"
                        value={values.coverImage}
                        onChange={onChangeCover}
                        initialValue={category?.cover && imageUrl(
                            window.stxConfig.imageTypes.serieCover,
                            category.id,
                            380,
                            192
                        )}
                        width={380}
                        height={192}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CategoryFormModal;