import React, { useState } from "react";

import { Icon, IconButton, TableCell, TableRow } from "@mui/material";
import SenderFormModal from "../SenderFormModal";
import WarningModal from "components/WarningModal";
import { getAxios } from "utils";

function SenderTile({ sender, updatedCallback }) {
    const [open, setOpen] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);

    const { id, name, host, port, user, from } = sender;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    }
    
    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/emailSenders';
        const params = { id }

        try {
            await axiosInstance.delete(uri, { params });
            updatedCallback(true);
            handleCloseDeleteWarning();
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }
    
    const handleOpenDeleteWarning = (event) => {
        event.stopPropagation();
        setDeleteWarning(true);
    }
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Sender'
                message={`Tem certeza que deseja deletar o sender ${name}?`}
            />
            <SenderFormModal open={open} handleClose={handleClose} sender={sender} />
            <TableRow style={{ cursor: 'pointer' }} onClick={handleOpen}>
                <TableCell scope="row">{id}</TableCell>
                <TableCell scope="row">{name}</TableCell>
                <TableCell scope="row">{host}</TableCell>
                <TableCell scope="row">{port}</TableCell>
                <TableCell scope="row">{user}</TableCell>
                <TableCell scope="row">{from}</TableCell>
                <TableCell scope="row">
                    <IconButton onClick={handleOpenDeleteWarning}>
                        <Icon>delete</Icon>
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}

export default SenderTile;
