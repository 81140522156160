import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from "prop-types";

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Alert
} from "@mui/material";

import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";
import DefaultTextField from "components/DefaultTextField";

function NotificationFormModal({ open, onClose, notification }) {
    const navigate = useNavigate();

    const [error, setError] = useState(undefined);
    const [title, setTitle] = useState(notification != null
        ? notification.name 
        : 'Nova Notificação'
    );

    const initialValues = { 
        title: notification?.title ?? '', 
        body: notification?.body ?? '',
        topic: notification?.topic ?? 'general'
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (notification != null) {
                const uri = '/proxy/admin/pushNotifications/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/pushNotifications';
                await axiosInstance.post(uri, formData);
            }
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        title: yup.string().required(),
        body: yup.string().required(),
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues,
        validationSchema,
        async onSubmit(values, { resetForm }) {
            if (await saveChanges(values)) {
                onClose();
                resetForm();
            }
        }
    });
    
    useEffect(() => {
        if (notification != null) {
            setTitle(values.name);
        }
    }, [notification, values.name])
    
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }
                <DefaultTextField 
                    label="Titulo"
                    name="title"
                    onChange={handleChange}
                    value={values.title}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                />
                <DefaultTextField 
                    label="Mensagem"
                    name="body"
                    multiline
                    onChange={handleChange}
                    value={values.body}
                    error={touched.body && Boolean(errors.body)}
                    helperText={touched.body && errors.body}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

NotificationFormModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default NotificationFormModal;