import { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button, Card, Table, TableBody, TableCell, TablePagination, TableRow } from "@mui/material";
import NotificationFormModal from "./components/NotificationFormModal";
import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import SearchBar from "components/SearchBar";
import { BarLoader } from "react-spinners";
import TablePaginationActions from "components/TablePaginationActions";
import MDAlert from "components/MDAlert";
import NotificationTile from "./components/NotificationTile";

const maxNotifications = 10;

function Notifications() {
    const [notifications, setNotifications] = useState([]);
    const [totalNotifications, setTotalNotifications] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();

    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleGetNotifications = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/pushNotifications";
        let response;
        const params = {
            search,
            limitStart: pageAssets * maxNotifications,
            limit: maxNotifications
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            if (err.response.data.error.code == 404) {
                setNotifications([]);
                setTotalNotifications(0);
                return;
            }

            setErro("Erro interno");
            return;
        }

        if (!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setNotifications(response.data.result.notifications);
        setTotalNotifications(response.data.result.total)
    }

    useEffect(async () => {
        await handleGetNotifications()
        setUpdate(false)
    }, [update, pageAssets, search]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setUpdate(true);
    }

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Notificações
        </MDTypography>
    </MDBox>

    const handleSearch = async (value) => {
        setSearch(value);
        setPageAssets(0);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                display='flex'
                justifyContent='flex-end'
            >
                <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={handleOpen}
                >
                    Adicionar
                </Button>
            </MDBox>
            <MDBox mt={6} mb={3}>
                <NotificationFormModal open={open} onClose={handleClose} />
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>Titulo</strong></TableCell>
                                    <TableCell><strong>Data</strong></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{notifications.map((notification, index) => <NotificationTile
                                key={`notification_${index}`}
                                notification={notification}
                                updatedCallback={setUpdate}
                            />
                            )}</TableBody>
                        </Table>
                        {isLoading &&
                            <BarLoader
                                color="#ACC0F0"
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination
                            rowsPerPageOptions={[maxNotifications]}
                            component="div"
                            count={totalNotifications}
                            rowsPerPage={maxNotifications}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </MDBox>
                </Card>

            </MDBox>
        </DashboardLayout>
    );
}

export default Notifications;
