import MDBox from "components/MDBox";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAxios } from "utils";
import { Box, Button, Divider, Icon, List } from "@mui/material";
import ChannelTile from "../ChannelTile";
import ChannelFormModal from "../ChannelFormModal";

function ChannelsTabPanel({ plan, value, index, onClose }) {
    const navigate = useNavigate();

    const [channels, setChannels] = useState([]);
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);

    const handleGetChannels = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/liveChannels";
        let response;
        const params = { optionId: plan.idOption }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }

        if (!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setChannels(response.data.result.channels);
    }

    useEffect(() => {
        handleGetChannels();
        setUpdate(false);
    }, [plan, update])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setUpdate(true);
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <MDBox p={3}>
                    <ChannelFormModal
                        handleClose={handleClose}
                        open={open}
                        planId={plan.idOption}
                    />
                    <Box
                        display='flex'
                        justifyContent='flex-end'
                    >
                        <Button
                            variant="contained"
                            style={{ color: 'white' }}
                            onClick={handleOpen}
                            startIcon={<Icon>add</Icon>}
                        >
                            Adicionar
                        </Button>
                    </Box>
                    {loading
                        ? <CircularProgress />
                        : <List>{channels.map((channel, index) =>
                            <Fragment key={`profile_${index}`}>
                                <ChannelTile
                                    channel={channel}
                                    planId={plan.idOption}
                                    updateCallBack={setUpdate}
                                />
                                {index < channels.length - 1 && <Divider />}
                            </Fragment>
                        )}</List>
                    }
                </MDBox>
            )}
        </div>
    )

}

export default ChannelsTabPanel;