import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Fragment, useState } from 'react';

function DefaultAutoCompleteField(props) {
    const { loading, onChange, options, value, ...other } = props;
    const [inputValue, setInputValue] = useState('');
    const [internalValue, setInternalValue] = useState(
        options.find(({ id }) => id == value)
    );

    return (
        <Autocomplete
            loading={loading}
            value={internalValue}
            onChange={(_, newValue) => {
                setInternalValue(newValue);
                onChange(newValue);
            }}
            options={options}
            inputValue={inputValue}
            onInputChange={(_, newInput) => setInputValue(newInput)}
            renderInput={(params) => <TextField
                variant="standard"
                {...params}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </Fragment>
                    ),
                }}
                {...other}
            />}
            {...other}
        />
    )
}

export default DefaultAutoCompleteField;
