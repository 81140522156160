import { useState } from "react";
import * as yup from 'yup';

import { Alert, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { getAxios } from "utils";
import DefaultSelectField from "components/DefaultSelectField";
import { useFormik } from "formik";
import DefaultSelectFromData from "components/DefaultSelectFromData";

function ContentFormModal({ open, onClose, categoryId = '' }) {
    const navigate = useNavigate();

    const [error, setError] = useState(null);

    const contentTypes = [
        { label: "Asset", value: "asset" },
        { label: "Categoria", value: "category" },
    ];

    const initialValues = { contentId: '', type: '' }

    const saveChanges = async (values = null) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        try {
            formData.append('categoryId', categoryId)
            formData.append('id', values.contentId)
            if (values.type == 'asset') {
                const uri = '/proxy/admin/assets/connect';
                await axiosInstance.post(uri, formData);
            } else if (values.type == 'category') {
                const uri = '/proxy/admin/categories/connect';
                await axiosInstance.post(uri, formData);
            } else {
                throw "Not Found";
            }
            onClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
        }
    }

    const validationSchema = yup.object({
        type: yup.string().required(),
        contentId: yup.number().required(),
    });

    const {
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues,
        onSubmit: saveChanges,
        validationSchema
    })
    
    const handleChangeType = (value) => {
        setFieldValue('type', value.target.value);
        setFieldValue('contentId', '');
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Adicionar Conteúdo</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }
                <DefaultSelectField
                    required
                    value={values.type}
                    label='Tipo'
                    name='type'
                    fullWidth
                    onChange={handleChangeType}
                    items={contentTypes}
                    error={touched.type && Boolean(errors.type)}
                    helperText={touched.type && errors.type}
                />
                {values.type == 'asset' &&
                    <DefaultSelectFromData
                        label="Asset"
                        open={true}
                        value={values.contentId}
                        name='contentId'
                        uri="/proxy/admin/assets"
                        parser={(result) => result.assets.map(asset => ({
                            label: asset.name,
                            id: asset.id
                        }))}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('contentId', value)
                        }}
                        error={touched.contentId && Boolean(errors.contentId)}
                        helperText={touched.contentId && errors.contentId}
                    />
                }
                {values.type == 'category' &&
                    <DefaultSelectFromData
                        label="Categoria"
                        open={true}
                        value={values.contentId}
                        name={'contentId'}
                        uri="/proxy/admin/categories"
                        parser={(result) => result.categories.map(category => ({
                            label: category.name,
                            id: category.id
                        }))}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('contentId', value)
                        }}
                        error={touched.contentId && Boolean(errors.contentId)}
                        helperText={touched.contentId && errors.contentId}
                    />
                }
                <Button color="warning" onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogContent>
        </Dialog>
    )
}

export default ContentFormModal;