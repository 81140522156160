import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { 
    Dialog, DialogContent, DialogTitle, Tabs, Tab, Box  
} from "@mui/material";
import UserDataTabPanel from "../UserDataTabPanel";
import ProfileTabPanel from "../ProfileTabPanel";
import UserPlanTabPanel from "../UserPlanTabPanel";

function UserModal({ open, handleClose, user }) {
    const [page, setPage] = useState(0);
    const { name, lastName } = user;
    const fullName = name + ' ' + (lastName ?? '');

    const handleChangePage = (event, newPage) => setPage(newPage);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            scroll="paper"
        >
            <DialogTitle>{fullName}</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={page} onChange={handleChangePage} aria-label="basic tabs example">
                        <Tab label="Dados" />
                        <Tab label="Perfil" />
                        <Tab label="Planos" />
                    </Tabs>
                </Box>
                <UserDataTabPanel onClose={handleClose} value={page} user={user} index={0} />
                <ProfileTabPanel value={page} user={user} index={1} />
                <UserPlanTabPanel value={page} user={user} index={2} />
            </DialogContent>
        </Dialog>
    )
}

UserModal.propType = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default UserModal;
