import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import ScreenTile from "./components/ScreenTile";

import {
    Card, Table, TableBody, TableRow, TableCell, Button, TablePagination, Box
} from "@mui/material";

import { getAxios } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "components/SearchBar";
import ScreenFormModal from "./components/ScreenFormModal";

const maxScreens = 10;

function Screens() {
    const [screens, setScreens] = useState([]);
    const [totalScreens, setTotalScreens] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();

    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleGetScreens = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/screens";
        let response;
        const params = {
            search,
            limitStart: pageAssets * maxScreens,
            limit: maxScreens
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }

        if (!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setScreens(response.data.result.screens);
        setTotalScreens(response.data.result.total)
    }

    useEffect(async () => {
        await handleGetScreens()
        setUpdate(false)
    }, [update, pageAssets, search]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setUpdate(true);
    }

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Telas
        </MDTypography>
    </MDBox>

    const handleSearch = async (value) => {
        setSearch(value);
        setPageAssets(0);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ScreenFormModal open={open} handleClose={handleClose} />
            <Box
                display='flex'
                justifyContent='flex-end'
            >
                <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={handleOpen}
                    startIcon={<AddIcon />}
                >
                    Adicionar
                </Button>
            </Box>
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>ID</strong></TableCell>
                                    <TableCell><strong>Nome</strong></TableCell>
                                    <TableCell><strong>Branch</strong></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{screens.map((screen, index) => <ScreenTile
                                key={`screen_${index}`}
                                screen={screen}
                                updatedCallback={setUpdate}
                            />
                            )}</TableBody>
                        </Table>
                        {isLoading &&
                            <BarLoader
                                color="#ACC0F0"
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination
                            rowsPerPageOptions={[maxScreens]}
                            component="div"
                            count={totalScreens}
                            rowsPerPage={maxScreens}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default Screens;