import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import { 
    Card, Table, TableBody, TableRow, TableCell, Button, TablePagination, Box, Icon 
} from "@mui/material";

import { getAxios } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';
import AvatarTile from "./components/AvatarTile";
import AvatarFormModal from "./components/AvatarFormModal";

const maxUsers = 10;

function Avatars() {
    const [avatars, setAvatars] = useState([]);
    const [totalAvatars, setTotalAvatars] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);

    const navigate = useNavigate();
    
    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleGetAvatars = async() => {
        const axiosInstance = getAxios();

        const uri = "/crm/admin/avatars";
        let response;
        const params = { 
            limitStart: pageAssets*maxUsers, 
            limit: maxUsers 
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setAvatars(response.data.result.avatars);
        setTotalAvatars(response.data.result.total)
    }

    useEffect(async() => { 
        await handleGetAvatars() 
        setUpdate(false)
    }, [update, pageAssets]);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        setUpdate(true);
    }

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Avatares
        </MDTypography>
    </MDBox>

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <AvatarFormModal open={open} handleClose={handleClose} />
            <Box
                display='flex'
                justifyContent='flex-end'
            >
                <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={handleOpen}
                    startIcon={<Icon>add</Icon>}
                >
                    Adicionar
                </Button>
            </Box>
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>Imagem</strong></TableCell>
                                    <TableCell><strong>Nome</strong></TableCell>
                                    <TableCell><strong>Ativo</strong></TableCell>
                                    <TableCell width="5"></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{avatars.map((avatar, index) =>
                                <AvatarTile
                                    key={`user_${index}`}
                                    avatar={avatar}
                                    updatedCallback={setUpdate}
                                />
                            )}</TableBody>
                        </Table>
                        {isLoading &&
                            <BarLoader
                                color="#ACC0F0" 
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination 
                            rowsPerPageOptions={[maxUsers]}
                            component="div"
                            count={totalAvatars}
                            rowsPerPage={maxUsers}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default Avatars;
