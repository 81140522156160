import { MobileDateTimePicker } from "@mui/x-date-pickers";
import MDBox from "components/MDBox";
import moment from "moment";

function DefaultDateTimeField(props) {
    const {value, onChange, ...other} = props

    return (
        <MDBox mt={1}>
            <MobileDateTimePicker
                ampm={false}
                format="DD/MM/YYYY HH:mm:ss"
                value={value != '' ? moment(value) : null}
                onChange={(value) => onChange(moment(value).format('YYYY-MM-DD HH:mm:ss'))}
                slotProps={{ textField: { variant: 'standard', ...other }}}
                {...other}
            />
        </MDBox>
    )
}

export default DefaultDateTimeField;
