import { imageUrl } from "utils";

function Asset({ id, designMode }) {
    let imageType = 0;

    switch (designMode.value) {
        case 1:
            imageType = window.stxConfig.imageTypes.movieEpisodeCover;
            break;
        case 2:
            imageType = window.stxConfig.imageTypes.movieEpisodePoster;
            break;
    }

    return (
        <img
            src={imageUrl(imageType, id, designMode.width, designMode.height)}
            width={designMode.width}
            height={designMode.height}
        />
    )
}

export default Asset;